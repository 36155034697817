import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Icon,
  Input,
} from "semantic-ui-react";
import styled from "styled-components";
import { InitialUserInfo } from "../../App";
import {
  Ages,
  FamilyMember,
  Gender,
  HouseSituation,
  LedgerRow,
  Pet,
  UserInformation,
  getTranslatedAges,
  getTranslatedGenders,
  getTranslatedSfoCategories,
  getTranslatedYesNo,
} from "../../models/models";

import BackForwardControls from "../../components/BackForwardControls";
import HelpTextModalGoal from "../../modals/HelpTextModalGoal";
import { JaNei } from "../../components/JaNei";
import StepHeader from "../../components/StepHeader";
import { StepDefinition, StepsState } from "../../components/Steps";
import PdfConverter from "../../services/PdfService/PdfConverter";
import {
  AdjustmentAmountPercent,
  LedgerRowId,
} from "../../components/ResultInteract";
import { v4 as uuidv4 } from "uuid";
import { StyledBoxSection } from "../../components/StyledBoxSection";
import { StyledGrid, StyledGridRow, StyledGridRowBottom } from "../MoneyIn";
import TrashIcon from "../../components/TrashIcon";
import HelpTextModalSalary from "../../modals/HelpTextModalSalary";
import i18next from "i18next";

import { DropdownTranslateable } from "../../components/DropdownTranslateable";
import HelpTextModalFamily from "../../modals/HelpTextModalFamily";

export interface UserDetailsProps {
  familyMembers: Array<FamilyMember>;
  pets: Array<Pet>;
  userDetails: UserInformation;
  activeStep: StepDefinition | undefined;
  steps: StepsState;
  addFamilyMember: (_: FamilyMember) => void;
  editFamilyMember: (_: FamilyMember) => void;
  deletePet: (id: string) => void;
  deleteFamilyMember: (id: string) => void;
  completeStep: () => void;
  goBack: () => void;
  goToStep: (step: StepDefinition) => void;
  setPreviousData: (data: any[]) => void;
  setFamilyMembers: (_: Array<FamilyMember>) => void;
  setLedger: (_: Array<LedgerRow>) => void;
  setUserDetails(_: UserInformation): void;
  setAdjustments(_: Map<LedgerRowId, AdjustmentAmountPercent>): void;
  addPet: (_: Pet) => void;
  setPets: (_: Array<Pet>) => void;
  editPet: (_: Pet) => void;
  resetSession: () => void;
}

export interface PdfFormat {
  previousData: any[]; // previous / historical sessions
  familyMembers: Array<FamilyMember>;
  ledger: Array<LedgerRow>;
  userDetails: UserInformation | undefined;
  adjustments: Map<LedgerRowId, AdjustmentAmountPercent>;
  pets: Array<Pet>;
}

// const genders: string[] = [
//   i18next.t<string>("family.genderselections.male"),
//   i18next.t<string>("family.genderselections.female"),
//   i18next.t<string>("family.genderselections.nonbinary"),
//   i18next.t<string>("family.genderselections.prefernot"),
// ];

const cars: number[] = [0, 1, 2, 3, 4];
const yesno: string[] = [i18next.t<string>("yes"), i18next.t<string>("no")];
const sfo: string[] = [
  i18next.t<string>("no"),
  i18next.t<string>("family.sfo.fulltime"),
  i18next.t<string>("family.sfo.parttime"),
];

interface DropDownItem {
  key: string | number;
  text: string | number;
  value: string | number;
}

export enum Optionals {
  Pregnant = "Pregnant",
  Sfo = "SFO",
  Student = "Student",
  Kindergarden = "Kindergarten",
  FreeSfo = "freeSfo",
}

// const getOptionalsTranslated = (option: Optionals) =>
//   ({
//     [Optionals.Pregnant]: i18next.t<string>("family.optionals.pregnant"),
//     [Optionals.Sfo]: i18next.t<string>("family.optionals.sfo"),
//     [Optionals.Student]: i18next.t<string>("family.optionals.student"),
//     [Optionals.Kindergarden]: i18next.t<string>(
//       "family.optionals.kindergarden"
//     ),
//     [Optionals.FreeSfo]: i18next.t<string>("family.optionals.freesfocore"),
//   }[option]);

const mapToOptionals = (familyMember: FamilyMember) => {
  const optionals: string[] = [];
  if (familyMember.gender !== "male") {
    if (
      familyMember.age === Ages.year14_17.toString() ||
      familyMember.age === Ages.year18_19.toString() ||
      familyMember.age === Ages.year20_30.toString() ||
      familyMember.age === Ages.year31_50.toString()
    ) {
      optionals.push(Optionals.Pregnant);
    }
  }
  if (
    familyMember.age === Ages.year1.toString() ||
    familyMember.age === Ages.year2.toString() ||
    familyMember.age === Ages.year3.toString() ||
    familyMember.age === Ages.year4_5.toString() ||
    familyMember.age === Ages.year4_5.toString()
  ) {
    optionals.push(Optionals.Kindergarden);
  } else if ([Ages.year6_9, Ages.year10_13].includes(familyMember.age)) {
    optionals.push(Optionals.Sfo);
    optionals.push(Optionals.FreeSfo);
  } else if ([Ages.year20_30].includes(familyMember.age)) {
    optionals.push(Optionals.Student);
  }
  return optionals;
};

const convertDropdownItem = (item: string): DropDownItem => {
  return {
    key: item,
    text: item,
    value: item,
  };
};

const convertDropdownItemNumber = (item: number): DropDownItem => {
  return {
    key: item,
    text: item,
    value: item,
  };
};

export const firstStep = "/familie";

export default function UserDetails(props: UserDetailsProps) {
  const [pdfDropped, setPdfDropped] = useState<boolean>(false);

  const ageOptions: DropDownItem[] = Object.values(Ages).map((item) => {
    return convertDropdownItem(item);
  });

  const genderOptions: DropDownItem[] = Object.values(Gender).map((item) => {
    return convertDropdownItem(item);
  });

  const carOptions: DropDownItem[] = cars.map((item) => {
    return convertDropdownItemNumber(item);
  });

  const yesnoOptions: DropDownItem[] = yesno.map((item) => {
    return convertDropdownItem(item.toString());
  });

  const sfoOptions: DropDownItem[] = sfo.map((item) => {
    return convertDropdownItem(item.toString());
  });

  const {
    setFamilyMembers,
    editFamilyMember,
    setLedger,
    setUserDetails,
    setPreviousData,
    setAdjustments,
    addPet,
    editPet,
    addFamilyMember,
    completeStep,
    goBack,
    goToStep,
    familyMembers,
    steps,
    userDetails,
    pets,
    setPets,
    deletePet,
    deleteFamilyMember,
  } = props;
  const onDrop = useCallback((acceptedFiles) => {
    const fileReader = new FileReader();
    fileReader.onload = async (event) => {
      try {
        if (event?.target?.readyState === FileReader.DONE) {
          const attachments = await PdfConverter.getAttachmentAsObject(
            event.target.result
          );
          setPreviousData(attachments.previousData);
          setFamilyMembers(attachments.familyMembers);
          setLedger(attachments.ledger);
          setUserDetails(attachments.userDetails || InitialUserInfo);
          setAdjustments(attachments.adjustments);
          setPets(attachments.pets);
        }
        setPdfDropped(true);
      } catch (err) {
        console.error("Load PDF error", err);
        alert(
          "There was an issue loading the PDF. Did you load the correct file?"
        );
      }
    };
    fileReader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const editFamilyMemberSub = (
    fm: FamilyMember,
    value: any,
    dataKey: string
  ) => {
    if (dataKey.includes("gender")) {
      editFamilyMember({ ...fm, gender: value as Gender });
    }
    if (dataKey.includes("age")) {
      editFamilyMember({ ...fm, age: value as Ages });
    }
    if (dataKey.includes("name")) {
      editFamilyMember({ ...fm, name: value });
    }
    if (dataKey.includes("student")) {
      editFamilyMember({ ...fm, student: value });
    }
    if (dataKey.includes("pregnant")) {
      editFamilyMember({ ...fm, pregnant: value });
    }
    if (dataKey.includes("sfo")) {
      editFamilyMember({ ...fm, sfo: value });
    }
    if (dataKey.includes("freeSfo")) {
      editFamilyMember({ ...fm, freeSfo: value });
    }
    if (dataKey.includes("kindergarten")) {
      editFamilyMember({ ...fm, kindergarden: value });
    }
  };

  const getOptionalField = (
    fm: FamilyMember,
    dataKey: string,
    header: string,
    initialValue: string
  ) => {
    return (
      <StyledPadding>
        {header}
        <DropdownTranslateable
          items={getTranslatedYesNo()}
          onChangeParent={editFamilyMemberSub}
          dataObject={fm}
          dataKey={dataKey}
          placeholder={header}
          value={initialValue}
        />
      </StyledPadding>
    );
  };

  const getOptionalSfoField = (
    fm: FamilyMember,
    dataKey: string,
    header: string,
    initialValue: string
  ) => {
    return (
      <StyledPadding>
        {header}
        <DropdownTranslateable
          items={getTranslatedSfoCategories()}
          onChangeParent={editFamilyMemberSub}
          dataObject={fm}
          dataKey={dataKey}
          placeholder={header}
          value={initialValue}
        />
      </StyledPadding>
    );
  };

  return (
    <StyledBackgroundColour>
      <StyledHeader>
        <StepHeader steps={steps} goToStep={goToStep} />
      </StyledHeader>
      <Container>
        <StyledContainerSpace>
          {!pdfDropped ? (
            <StyledBoxSection {...getRootProps()}>
              <input {...getInputProps()} />
              {isDragActive ? (
                <StyledDragParagraphActive>
                  {i18next.t("intro.droppfile")}
                </StyledDragParagraphActive>
              ) : (
                <div>
                  <Styledtitle>{i18next.t("intro.donethisbefore")}</Styledtitle>
                  <Ingress> {i18next.t("intro.upladdesc")}</Ingress>
                  <Button
                    circular
                    color="blue"
                    alt={i18next.t("intro.buttonalt")}
                  >
                    {i18next.t("intro.button")}
                  </Button>
                </div>
              )}
            </StyledBoxSection>
          ) : null}

          <StyledHeadingDiv>
            <h1>{i18next.t<string>("family.familymembers")}</h1>
            <h3>{i18next.t<string>("family.whosinthefamily")}</h3>
            <HelpTextModalFamily />
            <StyledBoxSection>
              <StyledGrid>
                {familyMembers
                  ? familyMembers.map((fm) => {
                      return (
                        <StyledGridRow key={fm.id}>
                          <Grid.Column width={5}>
                            {i18next.t<string>("family.name")}
                            <Input
                              fluid
                              placeholder={i18next.t<string>("family.name")}
                              onChange={(_, data) => {
                                editFamilyMember({
                                  ...fm,
                                  name: data.value?.toString(),
                                });
                              }}
                              labelPosition="right"
                              value={fm.name}
                            />
                          </Grid.Column>
                          <Grid.Column width={5} textAlign="left">
                            {i18next.t<string>("family.gender")}

                            <DropdownTranslateable
                              items={getTranslatedGenders()}
                              onChangeParent={editFamilyMemberSub}
                              dataObject={fm}
                              dataKey="gender"
                              placeholder={i18next.t<string>("family.gender")}
                              value={fm.gender}
                            />

                            {mapToOptionals(fm).includes(Optionals.Pregnant)
                              ? getOptionalField(
                                  fm,
                                  "pregnant",
                                  i18next.t("family.optionals.pregnant"),
                                  "" + fm.pregnant?.toString()
                                )
                              : null}
                            {mapToOptionals(fm).includes(Optionals.Sfo)
                              ? getOptionalSfoField(
                                  fm,
                                  "sfo",
                                  i18next.t<string>("family.optionals.sfo"),
                                  "" + fm.sfo?.toString()
                                )
                              : null}
                            {mapToOptionals(fm).includes(Optionals.Kindergarden)
                              ? getOptionalField(
                                  fm,
                                  "kindergarten",
                                  i18next.t<string>(
                                    "family.optionals.kindergarden"
                                  ),
                                  "" + fm.kindergarden?.toString()
                                )
                              : null}
                            {mapToOptionals(fm).includes(Optionals.Student) &&
                            !mapToOptionals(fm).includes(Optionals.Pregnant)
                              ? getOptionalField(
                                  fm,
                                  "student",
                                  i18next.t("family.optionals.student"),
                                  "" + fm.student?.toString()
                                )
                              : null}
                          </Grid.Column>
                          <Grid.Column width={5} textAlign="left">
                            {i18next.t<string>("family.age")}

                            <DropdownTranslateable
                              items={getTranslatedAges()}
                              onChangeParent={editFamilyMemberSub}
                              dataObject={fm}
                              dataKey="age"
                              placeholder={i18next.t<string>("family.age")}
                              value={fm.age}
                            />
                            {mapToOptionals(fm).includes(Optionals.FreeSfo)
                              ? getOptionalField(
                                  fm,
                                  "freeSfo",
                                  i18next.t("family.optionals.freesfocore"),
                                  "" + fm.freeSfo?.toString()
                                )
                              : null}
                            {mapToOptionals(fm).includes(Optionals.Student) &&
                            mapToOptionals(fm).includes(Optionals.Pregnant)
                              ? getOptionalField(
                                  fm,
                                  "student",
                                  i18next.t("family.optionals.student"),
                                  "" + fm.student?.toString()
                                )
                              : null}
                          </Grid.Column>
                          <Grid.Column
                            width={1}
                            verticalAlign="top"
                            textAlign="right"
                          >
                            <TrashIcon
                              color="blue"
                              itemId={fm.id}
                              onClick={deleteFamilyMember}
                            />
                          </Grid.Column>
                        </StyledGridRow>
                      );
                    })
                  : null}
                <StyledGridRowBottom>
                  <Grid.Column width={16}>
                    <Button
                      circular
                      color="blue"
                      onClick={() => {
                        addFamilyMember({
                          id: uuidv4(),
                          name: "",
                          age: Ages.unknown,
                          gender: Gender.prefernot,
                        });
                      }}
                    >
                      <Icon name="plus" />
                      {i18next.t<string>("family.addfamilymember")}
                    </Button>
                  </Grid.Column>
                </StyledGridRowBottom>
              </StyledGrid>
            </StyledBoxSection>
          </StyledHeadingDiv>

          <StyledHeadingDiv>
            <h1>{i18next.t<string>("family.otherinfo.header")}</h1>
            <HelpTextModalSalary />
            <StyledBoxSection>
              <StyledGrid>
                <StyledGridRow>
                  <Grid.Column width={8} textAlign="left">
                    {i18next.t<string>("family.otherinfo.numberoffossilcars")}
                    <Dropdown
                      fluid
                      search
                      selection
                      placeholder={i18next.t<string>(
                        "family.otherinfo.numberoffossilcars"
                      )}
                      options={carOptions}
                      onChange={(_, data) => {
                        setUserDetails({
                          ...userDetails,
                          car: {
                            ...userDetails.car,
                            fossil: data?.value as number,
                          },
                        });
                      }}
                      value={userDetails?.car?.fossil}
                    />
                    <StyledPadding>
                      {i18next.t<string>("family.otherinfo.yearlyincome")}
                      <Input
                        fluid
                        type="number"
                        placeholder={i18next.t<string>(
                          "family.otherinfo.yearlyincome"
                        )}
                        onChange={(_, data) => {
                          setUserDetails({
                            ...userDetails,
                            salary: parseInt(data?.value),
                          });
                        }}
                        labelPosition="right"
                        value={userDetails.salary}
                      />
                    </StyledPadding>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="left">
                    {i18next.t<string>("family.otherinfo.numberofelectriccars")}
                    <Dropdown
                      fluid
                      search
                      selection
                      placeholder={i18next.t<string>(
                        "family.otherinfo.numberofelectriccars"
                      )}
                      options={carOptions}
                      onChange={(_, data) => {
                        setUserDetails({
                          ...userDetails,
                          car: {
                            ...userDetails.car,
                            electric: data?.value as number,
                          },
                        });
                      }}
                      value={userDetails?.car?.electric}
                    />
                  </Grid.Column>
                </StyledGridRow>
              </StyledGrid>
            </StyledBoxSection>
          </StyledHeadingDiv>

          <StyledHeadingDiv>
            <h1>{i18next.t<string>("family.housing.header")}</h1>

            <JaNei
              optionOneSelected={userDetails.house === HouseSituation.OWN}
              optionOneText={i18next.t<string>("family.housing.own")}
              optionOneClick={() => {
                setUserDetails({
                  ...userDetails,
                  house: HouseSituation.OWN,
                });
              }}
              optionTwoSelected={userDetails.house === HouseSituation.RENT}
              optionTwoText={i18next.t<string>("family.housing.rent")}
              optionTwoClick={() => {
                setUserDetails({
                  ...userDetails,
                  house: HouseSituation.RENT,
                });
              }}
            />
          </StyledHeadingDiv>

          <StyledHeadingDiv>
            <h1>{i18next.t<string>("family.animals.header")}</h1>
            <StyledBoxSection>
              <StyledGrid>
                {pets.length ? (
                  <Grid.Row>
                    <Grid.Column width={9}>
                      <strong>
                        {i18next.t<string>("family.animals.name")}
                      </strong>
                    </Grid.Column>
                    <Grid.Column width={5} textAlign="left">
                      <strong>
                        {i18next.t<string>("family.animals.kind")}
                      </strong>
                    </Grid.Column>
                  </Grid.Row>
                ) : null}
                {pets
                  ? pets.map((pet) => {
                      return (
                        <StyledGridRow key={pet.id}>
                          <Grid.Column width={9}>
                            <Input
                              fluid
                              placeholder={i18next.t<string>(
                                "family.animals.name"
                              )}
                              onChange={(_, data) => {
                                editPet({
                                  ...pet,
                                  name: data.value?.toString(),
                                });
                              }}
                              labelPosition="right"
                              value={pet.name}
                            />
                          </Grid.Column>
                          <Grid.Column width={5} textAlign="left">
                            <Input
                              fluid
                              placeholder={i18next.t<string>(
                                "family.animals.kindplaceholder"
                              )}
                              onChange={(_, data) => {
                                editPet({
                                  ...pet,
                                  type: data.value?.toString(),
                                });
                              }}
                              labelPosition="right"
                              value={pet.type}
                            />
                          </Grid.Column>
                          <Grid.Column
                            width={2}
                            verticalAlign="middle"
                            textAlign="center"
                          >
                            <TrashIcon
                              color="blue"
                              itemId={pet.id}
                              onClick={deletePet}
                            />
                          </Grid.Column>
                        </StyledGridRow>
                      );
                    })
                  : null}
                <StyledGridRowBottom>
                  <Grid.Column width={16}>
                    <Button
                      circular
                      color="blue"
                      onClick={() => {
                        addPet({
                          id: uuidv4(),
                          name: "",
                          type: "",
                        });
                      }}
                    >
                      <Icon name="plus" />
                      {i18next.t<string>("family.animals.addanimal")}
                    </Button>
                  </Grid.Column>
                </StyledGridRowBottom>
              </StyledGrid>
            </StyledBoxSection>
          </StyledHeadingDiv>

          <StyledHeadingDiv>
            <h1>{i18next.t<string>("family.savingsgoal.header")}</h1>
            <HelpTextModalGoal />
            <Grid columns={2}>
              <Grid.Column width={10}>
                <Input
                  placeholder={i18next.t<string>(
                    "family.savingsgoal.placeholder"
                  )}
                  value={props.userDetails.goal?.name}
                  onChange={(_, data) => {
                    props.setUserDetails({
                      ...props.userDetails,
                      goal: {
                        name: data.value?.toString(),
                        amount: props.userDetails.goal?.amount || 0,
                      },
                    });
                  }}
                  style={{ width: "100%" }}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Input
                  placeholder={i18next.t<string>(
                    "family.savingsgoal.amountplaceholder"
                  )}
                  value={props.userDetails.goal?.amount || ""}
                  onChange={(_, data) => {
                    props.setUserDetails({
                      ...props.userDetails,
                      goal: {
                        name: props.userDetails.goal?.name || "",
                        amount: parseInt(data.value),
                      },
                    });
                  }}
                  label="kr"
                  labelPosition="right"
                  style={{ width: "100%" }}
                />
              </Grid.Column>
            </Grid>
          </StyledHeadingDiv>

          <BackForwardControls
            goBack={() => goBack()}
            completeStep={completeStep}
            noBack={true}
          />

          {/* </StyledBControlsDiv> */}
        </StyledContainerSpace>
      </Container>
    </StyledBackgroundColour>
  );
}

export const StyledBackgroundColour = styled.div`
  background-color: #f1f8f8;
  width: 100%;
`;

export const StyledContainerSpace = styled.div`
  padding-top: 3em;
  padding-bottom: 4em;
`;
export const StyledHeader = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

const StyledHeadingDiv = styled.div`
  position: relative;
  margin-bottom: 4em;
`;

const CenterTextDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledDragParagraphActive = styled.p`
  background-color: #f1f8f8 !important;
  font-weight: bold !important;
  border: 2px solid #3d8eb1;
  padding: 4em;
  border-radius: 3px;
`;

const Styledtitle = styled.h1`
  font-weight: bold !important;
  font-size: x-large;
  padding-top: 1em;
`;

const Ingress = styled.p`
  font-size: medium;
  padding-bottom: 2em;
`;

export const StyledIcon = styled(Icon)`
  &:hover {
    cursor: pointer;
  }
`;

const StyledPadding = styled.div`
  padding-top: 1rem;
`;
