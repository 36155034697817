import { PDFDocument, PDFPage, StandardFonts, rgb } from "pdf-lib";
import {
  Car,
  FamilyMember,
  HouseSituation,
  LedgerRow,
  Pet,
  TransactionCategory,
  UserInformation,
  getTranslatedAges,
  getTranslatedAge
} from "../../models/models";
import Pd from "frontpage.png";
import {
  AdjustmentAmountPercent,
  LedgerRowId,
} from "../../components/ResultInteract";
import { calculateMoneyIn } from "../../data/Ledger";
import { calculateMoneyOut } from "../../data/Ledger";
import i18next from "i18next";

export interface CreatePdfProps {
  ledger: Array<LedgerRow>;
  familyMembers: Array<FamilyMember>;
  userDetails: UserInformation;
  adjustments: Map<LedgerRowId, AdjustmentAmountPercent>;
  previousData: any[];
  addImage: boolean;
  pets: Array<Pet>;
}

export class PdfWriterService {
  public static async createPdf(props: CreatePdfProps): Promise<Blob> {
    const pdfDoc = await PDFDocument.create();
    const objectToAttach = {
      version: "0.0.1",
      timestamp: Date.now(),
      familyMembers: props.familyMembers,
      ledger: props.ledger,
      userDetails: props.userDetails,
      adjustments: Array.from(props.adjustments.entries()),
      pets: props.pets,
    };
    const previous = props.previousData || [];
    console.log("objectToAttach", objectToAttach);
    previous.push(objectToAttach);
    const history = {
      history: previous,
    };
    const uint8array: Uint8Array = new TextEncoder().encode(
      JSON.stringify(history)
    );
    pdfDoc.attach(uint8array, "sos_state");
    let page = pdfDoc.addPage();

    const Savingspotential = (): number => {
      const moneyOut = calculateMoneyOut(props.ledger);
      const moneyIn = calculateMoneyIn(props.ledger);
      const res = moneyIn - moneyOut;
      return res;
    };

    if (props.addImage) {
      const FrontPageBytes = await fetch(
        i18next.t<string>("pdf.frontpage.filename")
      ).then((res) => res.arrayBuffer());
      const FrontPageImage = await pdfDoc.embedPng(FrontPageBytes);
      const FrontPageDims = FrontPageImage.scale(0.5);
      page.drawImage(FrontPageImage, {
        x: 100,
        y: 300,
        width: FrontPageDims.width,
        height: FrontPageDims.height,
        // rotate: degrees(30),
        // opacity: 0.75,
      });
    }
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const { height } = page.getSize();
    let y = 80;

    const checkY = (y: number) => {
      if (y > 700) {
        return true;
      } else {
        return false;
      }
    };

    const adjustedLedger = props.ledger.map((row) => {
      if (props.adjustments.has(row.id)) {
        const adjustment = parseInt(
          props.adjustments.get(row.id) || row.amount.toString()
        );
        return {
          ...row,
          amount: adjustment,
        };
      } else {
        return row;
      }
    });

    //TODO Create textfunction, and replace the drawtext
    page.drawText(i18next.t<string>("pdf.intro"), {
      x: 50,
      y: 254,
      size: 12,
      font: font,
    });
    page.drawText(i18next.t<string>("pdf.instruction"), {
      x: 50,
      y: 230,
      size: 12,
      font: font,
    });
    page.drawText(i18next.t<string>("pdf.description"), {
      x: 50,
      y: 206,
      size: 12,
      font: font,
    });
    page.drawText(i18next.t<string>("pdf.howtoreference"), {
      x: 50,
      y: 182,
      size: 12,
      font: font,
    });

    page = pdfDoc.addPage();

    page.drawText("1. " + i18next.t<string>("stepsbar.family.title"), {
      x: 50,
      y: height - y,
      size: 20,
      font: fontBold,
    });
    y = y + 26;

    page.drawText(i18next.t<string>("family.familymembers"), {
      x: 50,
      y: height - y,
      size: 12,
      font: fontBold,
    });
    y = y + 22;

    props.familyMembers.forEach((member) => {
      page.drawText(member.name + ", " + getTranslatedAge(member.age), {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      });
      y = y + 22;
    });

    page.drawText(i18next.t<string>("family.otherinfo.vehicles") + ":", {
      x: 50,
      y: height - y,
      size: 12,
      font: fontBold,
    });
    y = y + 22;

    page.drawText(
      i18next.t<string>("family.otherinfo.numberofelectriccars") +
        ": " +
        props.userDetails.car.electric,
      {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      }
    );
    y = y + 22;

    page.drawText(
      i18next.t<string>("family.otherinfo.numberoffossilcars") +
        ": " +
        props.userDetails.car.fossil,
      {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      }
    );
    y = y + 22;

    page.drawText(i18next.t<string>("family.housing.header"), {
      x: 50,
      y: height - y,
      size: 12,
      font: fontBold,
    });
    y = y + 22;

    const hasHouse = () => {
      if (props.userDetails.house === HouseSituation.OWN) {
        return i18next.t<string>("family.housing.own");
      } else if (props.userDetails.house === HouseSituation.RENT) {
        return i18next.t<string>("family.housing.rent");
      } else {
        return i18next.t<string>("no");
      }
    };
    page.drawText(hasHouse(), {
      x: 50,
      y: height - y,
      size: 12,
      font: font,
    });
    y = y + 22;

    page.drawText(i18next.t<string>("family.animals.header"), {
      x: 50,
      y: height - y,
      size: 12,
      font: fontBold,
    });
    y = y + 22;

    props.pets.forEach((pet) => {
      page.drawText(pet.name + " - " + pet.type, {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      });
      y = y + 22;
    });

    page.drawText(i18next.t<string>("family.savingsgoal.header"), {
      x: 50,
      y: height - y,
      size: 12,
      font: fontBold,
    });
    y = y + 22;

    page.drawText(
      props.userDetails.goal.name +
        " - " +
        props.userDetails.goal.amount +
        "kr",
      {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      }
    );
    y = y + 44;

    page.drawLine({
      start: { x: 80, y: height - y },
      end: { x: 520, y: height - y },
      thickness: 0.5,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });
    y = y + 44;

    page.drawText("2. " + i18next.t<string>("moneyin.header"), {
      x: 50,
      y: height - y,
      size: 20,
      font: fontBold,
    });
    y = y + 26;

    page.drawText(i18next.t<string>("moneyin.subheader"), {
      x: 50,
      y: height - y,
      size: 14,
      font: fontBold,
    });
    y = y + 24;

    props.ledger
      .filter((item) => item.accountTo === "user")
      .forEach((income) => {
        page.drawText(
          i18next.t<string>("moneyin.category") + ": " + i18next.t<string>("moneyin.categories." + income.accountFrom),
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyin.arrangement") + ": " + i18next.t<string>("moneyin.categories." + income.accountFrom),
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyin.amount") + ": " + income.amount + "kr",
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyin.paymentdate") +
            ": " +
            income.dayOfMonth +
            ".",
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 26;
        if (checkY(y)) {
          page = pdfDoc.addPage();
          y = 80;
        }
      });

    page.drawText(
      i18next.t<string>("moneyin.incomeplural") +
        ": " +
        calculateMoneyIn(props.ledger) +
        "kr " +
        i18next.t<string>("moneyin.permonth"),
      {
        x: 50,
        y: height - y,
        size: 14,
        font: fontBold,
      }
    );

    y = y + 44;

    page.drawLine({
      start: { x: 80, y: height - y },
      end: { x: 520, y: height - y },
      thickness: 0.5,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });
    y = y + 44;

    page = pdfDoc.addPage();
    y = 80;

    page.drawText("3. " + i18next.t<string>("moneyout.header"), {
      x: 50,
      y: height - y,
      size: 20,
      font: fontBold,
    });
    y = y + 26;

    page.drawText(i18next.t<string>("moneyout.debt.header"), {
      x: 50,
      y: height - y,
      size: 14,
      font: fontBold,
    });
    y = y + 24;

    props.ledger
      .filter((item) => (item.category === TransactionCategory.debt || item.category === TransactionCategory.carDebt || item.category === TransactionCategory.studentDebt || item.category === TransactionCategory.consumerDebt || item.category === TransactionCategory.mortgage || item.category === TransactionCategory.creditCardDebt))
      .forEach((debt) => {
        page.drawText(
          i18next.t<string>("moneyin.category") + ": " + i18next.t<string>("moneyout.expenses.categories." + debt.accountTo),
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyout.debt.interest") +
            ": " +
            (debt?.interest ? debt?.interest + "%" : "-"),
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyin.amount") + ": " + debt.amount + "kr",
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyout.debt.duedate") +
            ": " +
            debt.dayOfMonth +
            ".",
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 26;
      });
    y = y + 22;
    page.drawText(
      i18next.t<string>("moneyout.debt.totaldebt") +
        ": " +
        calculateMoneyOut(
          props.ledger.filter(
            (item) => ( item.category === TransactionCategory.debt || item.category === TransactionCategory.carDebt || item.category === TransactionCategory.studentDebt || item.category === TransactionCategory.consumerDebt || item.category === TransactionCategory.mortgage || item.category === TransactionCategory.creditCardDebt)

          )
        ) +
        "kr " +
        i18next.t<string>("moneyin.permonth"),
      {
        x: 50,
        y: height - y,
        size: 14,
        font: fontBold,
      }
    );
    y = y + 24;

    page.drawText(i18next.t<string>("moneyout.expenses.header"), {
      x: 50,
      y: height - y,
      size: 14,
      font: fontBold,
    });
    y = y + 24;

    props.ledger
      .filter(
        (item) =>
          item.accountFrom === "user" &&
          item.category !== TransactionCategory.debt &&
          item.category !== TransactionCategory.carDebt &&
          item.category !== TransactionCategory.studentDebt &&
          item.category !== TransactionCategory.consumerDebt &&
          item.category !== TransactionCategory.creditCardDebt &&
          item.category !== TransactionCategory.mortgage
      )
      .forEach((expense) => {
        page.drawText(
          i18next.t<string>("moneyin.category") + ": " + i18next.t<string>("moneyout.expenses.categories." + expense.accountTo),
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyin.amount") + ": " + expense.amount + "kr",
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 26;
        if (checkY(y)) {
          page = pdfDoc.addPage();
          y = 80;
        }
      });

    page.drawText(
      i18next.t<string>("moneyout.expenses.total") +
        ": " +
        calculateMoneyOut(
          props.ledger.filter(
            (item) =>
              item.accountFrom === "user" &&
              item.category !== TransactionCategory.debt &&
              item.category !== TransactionCategory.carDebt &&
              item.category !== TransactionCategory.studentDebt &&
              item.category !== TransactionCategory.consumerDebt &&
              item.category !== TransactionCategory.creditCardDebt &&
              item.category !== TransactionCategory.mortgage
          )
        ) +
        "kr " +
        i18next.t<string>("moneyin.permonth"),
      {
        x: 50,
        y: height - y,
        size: 14,
        font: fontBold,
      }
    );

    y = y + 44;

    page.drawLine({
      start: { x: 80, y: height - y },
      end: { x: 520, y: height - y },
      thickness: 0.5,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });
    y = y + 44;

    page = pdfDoc.addPage();
    y = 80;

    page.drawText("4. " + i18next.t<string>("stepsbar.result.title"), {
      x: 50,
      y: height - y,
      size: 20,
      font: fontBold,
    });
    y = y + 26;

    page.drawText(i18next.t<string>("result.balance.header"), {
      x: 50,
      y: height - y,
      size: 14,
      font: fontBold,
    });
    y = y + 24;

    page.drawText(
      i18next.t<string>("result.balance.moneyout") +
        ": " +
        calculateMoneyOut(props.ledger) +
        " kr",
      {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      }
    );

    y = y + 22;

    page.drawText(
      i18next.t<string>("result.balance.moneyin") +
        ": " +
        calculateMoneyIn(props.ledger) +
        " kr",
      {
        x: 50,
        y: height - y,
        size: 12,
        font: font,
      }
    );

    y = y + 24;

    {
      if (Savingspotential() > 0) {
        page.drawText(
          i18next.t<string>("result.balance.savingspotential") +
            ": " +
            Savingspotential(),
          {
            x: 50,
            y: height - y,
            size: 14,
            font: fontBold,
          }
        );
      }
    }
    {
      if (Savingspotential() < 0) {
        page.drawText(
          i18next.t<string>("result.balance.overspending") +
            ": " +
            Savingspotential(),
          {
            x: 50,
            y: height - y,
            size: 14,
            font: fontBold,
          }
        );
      }
    }

    y = y + 44;

    page.drawLine({
      start: { x: 80, y: height - y },
      end: { x: 520, y: height - y },
      thickness: 0.5,
      color: rgb(0, 0, 0),
      opacity: 0.75,
    });
    y = y + 44;

    page.drawText("5. " + i18next.t<string>("pdf.budget"), {
      x: 50,
      y: height - y,
      size: 20,
      font: fontBold,
    });
    y = y + 26;

    page.drawText(i18next.t<string>("pdf.adjustedexpenses"), {
      x: 50,
      y: height - y,
      size: 14,
      font: fontBold,
    });
    y = y + 24;

    adjustedLedger
      .filter(
        (item) =>
          item.accountFrom === "user" &&
          item.category !== TransactionCategory.debt &&
          item.category !== TransactionCategory.carDebt &&
          item.category !== TransactionCategory.studentDebt &&
          item.category !== TransactionCategory.consumerDebt &&
          item.category !== TransactionCategory.creditCardDebt &&
          item.category !== TransactionCategory.mortgage
      )
      .forEach((expense) => {
        page.drawText(
          i18next.t<string>("pdf.adjustedexpenses") + ": " + i18next.t<string>("moneyout.expenses.categories." + expense.accountTo),
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 22;
        page.drawText(
          i18next.t<string>("moneyout.expenses.amount") +
            ": " +
            expense.amount +
            "kr",
          {
            x: 50,
            y: height - y,
            size: 12,
            font: font,
          }
        );
        y = y + 26;

        if (checkY(y)) {
          page = pdfDoc.addPage();
          y = 80;
        }
      });

    y = y + 24;

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" }); // change resultByte to bytes

    return blob;
    // const pdfHandler = new PdfHandler(pdfBytes);
    // const attachments = await pdfHandler.getAttachments();
    // // const attachmentsAsObject = attachments.map((a) => {
    // //      const decoded = new TextDecoder().decode(a.data);
    // //      return JSON.parse(decoded)
    // // })

    // const link=document.createElement('a');
    // link.href=window.URL.createObjectURL(blob);
    // link.download="myFileName.pdf";
    // link.click();
  }
}

export default PdfWriterService;
