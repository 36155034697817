import {
  StepDefinition,
  StepGroup,
  StepGroupType,
  StepsState,
} from "../components/Steps";
import { progressStep } from "./StepProgressor";
import i18next from "./../i18n";

export const StepsInitialState: Array<StepDefinition> = [
  {
    id: 0,
    group: StepGroupType.FAMILY,
    completed: false,
    path: "/familie",
    heading: "Familieoversikt",
    description:
      "Før vi starter ønsker vi å få en oversikt over familiesituasjonen din og litt grunnlegende innsikt.",
  },
  {
    id: 1,
    group: StepGroupType.MONEY_IN,
    completed: false,
    path: "/penger-inn",
    heading: "Penger inn",
    description:
      "I dette punktet ønsker vi å få en oversikt over dine inntektskilder og hvor mye penger inn du får i løpet av en måned.",
  },
  {
    id: 2,
    group: StepGroupType.MONEY_OUT,
    completed: false,
    path: "/penger-ut",
    heading: "Penger ut",
    description:
      "I dette punktet ønsker vi å kartlegge både faste og løpende utgifter, samt din gjeldssituasjon",
  },
  {
    id: 3,
    group: StepGroupType.RESULTS,
    completed: false,
    path: "/resultat",
    heading: "Oversikt",
  },
  /*{
    id: 4,
    group: StepGroupType.NEXTSTEPS,
    completed: false,
    path: "/nestesteg",
    heading: "Neste Steg",
  },*/
  {
    id: 5,
    group: StepGroupType.END,
    completed: false,
    path: "/end",
    heading: "Resultat",
  },
];

export interface StateSummary {
  familyMemberCount: number | undefined;
  moneyIn: string | undefined;
  moneyOut: string | undefined;
}

export const DefaultStateSummary: StateSummary = {
  familyMemberCount: undefined,
  moneyIn: undefined,
  moneyOut: undefined,
};

const familyTxt = (familyMemberCount: number | undefined): string => {
  if (familyMemberCount === undefined || familyMemberCount === 0) {
    return i18next.t<string>("stepsbar.family.subtitle");
  }

  if (familyMemberCount === 1)
    return (
      `${familyMemberCount} ` + i18next.t<string>("stepsbar.family.person")
    );

  return `${familyMemberCount} ` + i18next.t<string>("stepsbar.family.persons");
};

export const StepGroups = (
  stateSummary: StateSummary
): Map<StepGroupType, StepGroup> => {
  const { familyMemberCount } = stateSummary;
  const stepGroups: Map<StepGroupType, StepGroup> = new Map<
    StepGroupType,
    StepGroup
  >();
  stepGroups.set(StepGroupType.FAMILY, {
    title: i18next.t<string>("stepsbar.family.title"),
    description: familyTxt(familyMemberCount),
  });
  stepGroups.set(StepGroupType.MONEY_IN, {
    title: i18next.t<string>("stepsbar.in.title"),
    description: stateSummary.moneyIn
      ? `${stateSummary.moneyIn} kr`
      : i18next.t<string>("stepsbar.in.subtitle"),
  });
  stepGroups.set(StepGroupType.MONEY_OUT, {
    title: i18next.t<string>("stepsbar.out.title"),
    description: stateSummary.moneyOut
      ? `${stateSummary.moneyOut} kr`
      : i18next.t<string>("stepsbar.out.subtitle"),
  });
  stepGroups.set(StepGroupType.RESULTS, {
    title: i18next.t<string>("stepsbar.result.title"),
    description: i18next.t<string>("stepsbar.result.subtitle"),
  });
  /*
  stepGroups.set(StepGroupType.NEXTSTEPS, {
    title: i18next.t<string>("stepsbar.nextstep.title"),
    description: i18next.t<string>("stepsbar.nextstep.subtitle"),
  });
  */
  return stepGroups;
};

export const InitialStepsWithoutPath = (
  stateSummary: StateSummary
): StepsState => {
  return {
    activeStepId: 0,
    steps: StepsInitialState,
    stepGroups: StepGroups(stateSummary),
    completedGroups: new Set<StepGroupType>(),
  };
};

export const InitialStepsWithPath = (
  path: string,
  stateSummary: StateSummary,
  completedGroups: Set<StepGroupType>
): StepsState => {
  const steps = InitialStepsWithoutPath(stateSummary);
  const current = StepsInitialState.find((s) => s.path === path);

  return {
    ...steps,
    activeStepId: current?.id || 0,
    completedGroups: completedGroups,
  };
};
