import i18next from "i18next";
import AdviceModalTemplate from "../AdviceModalTemplate";
import styled from "styled-components";

export default function AdviceModalBenefit() {
  function Hyperlink({ title, url }: { title: string; url: string }) {
    return (
      <a href={url} target="_blank">
        {" " + title}
      </a>
    );
  }
  return (
    <AdviceModalTemplate
      title={i18next.t<string>("nextsteps.income.benefits.title")}
      subtitle={i18next.t<string>("nextsteps.income.benefits.subtitle")}
    >
      <div>
        <ul>
          <li>
            Hvis noen av barna går på fritidsaktiviteter kan du søke om støtte
            til medlemskap og kontingent, utstyr, cuper og lignende via klubben.
            Send en mail til lagleder, så skal vedkommende ta dette videre slik
            at du får hjelp. Bor du i Oslo kan du også søke hjelp hos
            <Hyperlink
              title="Aktivitetsguiden"
              url="https://www.idrettsforbundet.no/idrettskrets/oslo/inkludering/aktivitetsguide/"
            />
            . For Oslo og resten av landet kan man også finne ordninger via
            <Hyperlink title="UngFritid" url="https://stotte.ungfritid.no/" />.
          </li>
          <li>
            Hvis skoleaktiviteter som turer, skikurs eller lignende koster
            penger, kan du søke om støtte via skolen. Send mail til skolen,
            læreren eller rektor.
          </li>
          <li>
            Trenger du bostøtte,{" "}
            <Hyperlink
              title="sjekk og søk her"
              url="https://www.husbanken.no/person/bostotte/kan-jeg-faa-bostotte/"
            />{" "}
            (Husbanken)
          </li>
          <li>
            Trenger du støtte til barnehage,{" "}
            <Hyperlink
              title="sjekk om du kan få det"
              url="https://www.udir.no/regelverk-og-tilsyn/barnehage/foreldrebetaling/"
            />{" "}
            (udir.no) og så via egen kommune.
          </li>
          <li>
            Barn som skal begynne på, eller går på videregående skole, kan ha
            krav på{" "}
            <Hyperlink
              title="inntektsavhengig stipend"
              url="https://lanekassen.no/inntektsavhengig-stipend"
            />{" "}
            (lanekassen.no). De fleste elever på videregående skoler kan uansett
            få{" "}
            <Hyperlink
              title="utstyrsstipend"
              url="https://lanekassen.no/nb-NO/stipend-og-lan/norge/videregaende-skole/videregaende-skole/"
            />
            .
          </li>
          <li>
            NAV har også støtteordninger:
            <ul>
              <li>
                <Hyperlink
                  title="Helt eller delvis alene med barn?"
                  url="https://www.nav.no/alene-med-barn"
                />{" "}
                (nav.no)
              </li>
              <li>
                <Hyperlink
                  title="Økonomisk sosialhjelp"
                  url="https://www.nav.no/okonomisk-sosialhjelp"
                />{" "}
                (nav.no)
              </li>
            </ul>
          </li>
        </ul>
        <StyledItalic>
          Kilde:{" "}
          <Hyperlink
            title="DNB"
            url="https://www.dnb.no/dnbnyheter/no/din-okonomi/slik-oker-du-inntektene-dine"
          />
        </StyledItalic>
      </div>
    </AdviceModalTemplate>
  );
}

const StyledItalic = styled.div`
  font-style: italic;
`;
