import styled from "styled-components";
import {
  Button,
  Header,
  Icon,
  Segment,
  TransitionablePortal,
} from "semantic-ui-react";
import { Fragment, useState } from "react";
import i18next from "i18next";

interface AdviceModalTemplateProps {
  title: string;
  subtitle: string;
  children: string | JSX.Element | JSX.Element[];
}

export default function AdviceModalTemplate(props: AdviceModalTemplateProps) {
  const { title, subtitle, children } = props;
  const transition = {
    animation: "fly left",
    duration: 300,
  };

  const [open, setOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <StyledButton onClick={() => setOpen(true)}>
        {i18next.t<string>("nextsteps.read_how")}
        <Icon name="angle right" />
      </StyledButton>

      <TransitionablePortal
        onClose={() => setOpen(false)}
        open={open}
        transition={transition}
      >
        <StyledSegment
          style={{
            right: "0",
            position: "fixed",
            top: "0",
            bottom: "0",
            zIndex: 1000,
          }}
        >
          <Button icon circular color="blue" onClick={() => setOpen(false)}>
            <Icon name="close" />
          </Button>
          <StyledModalSidebar>
            <StyledDiv>
              <h1>{title}</h1>
              <StyledSubheader>{subtitle}</StyledSubheader>
              {children}
            </StyledDiv>
          </StyledModalSidebar>
        </StyledSegment>
      </TransitionablePortal>
    </Fragment>
  );
}

export const LabelButton = styled.button`
  text-decoration: underline;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  color: #3d8eb1;
  background-color: #f1f8f8;
  font-family: Montserrat !important;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const StyledModalSidebar = styled.div`
  padding: 3em;
`;
const StyledDiv = styled.div`
  width: 40em;
`;

const StyledSegment = styled(Segment)`
  margin-top: 0px !important;
  overflow: scroll;
`;
const StyledButton = styled.button`
  background-color: white;
  border: none;
  color: #3d8eb1;
  padding: 15px 10px 10px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
`;

const StyledSubheader = styled.h4`
  padding-bottom: 2.5rem;
`;

const StyledHeader = styled.h1`
  padding-bottom: 2.5rem;
`;
