import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      yes: "Yes",
      no: "No",
      goback: "Back",
      gonext: "Next",
      finish: "Finish",
      feedback: "Send feedback",
      stepsbar: {
        family: {
          title: "Family",
          subtitle: "Family overview",
          person: "person",
          persons: "people",
        },
        in: {
          title: "Incoming money",
          subtitle: "Payment and velfare",
        },
        out: {
          title: "Outgoing money",
          subtitle: "Debt and expenses",
        },
        result: {
          title: "Result",
          subtitle: "Your economy",
        },
        nextstep: {
          title: "Next steps",
          subtitle: "Summary and advice",
        },
      },
      home: {
        headername: "Economy Manager",
        sloagan: "- get a better overview of your financial situation",
        start: "Start new mapping",
        what: {
          header: "What is the Economy Manager?",
          text: "Welcome to the Economy Manager! The aim of this tool is to give you a better overview of your spending. By getting a better overview, we believe that it will be easier to take the necessary steps to get a more robust economy and a simpler everyday life.",
          usage:
            "Use the tool to enter your household's income and monthly expenses. You will then get an overview of whether you are overspending or have potential to increase your savings or decrease your debt.",
          usage2:
            "You will find a simple budget planner where you can visually experiment with your budget. When looking at your expenses and a reference budget, you can adjust and see where to save costs and how it might affect your finances in the longer term.",
        },
      },
      intro: {
        droppfile: "Drag and drop file",
        donethisbefore: "Are you a returning user?",
        upladdesc:
          "If you downladed a PDF on your last visit, it can be uploaded here. It contains data from the last session, and you will not have to reenter all your data.",
        button: "Upload PDF",
        buttonalt: "Upload PDF from previous sessions",
      },
      family: {
        familymembers: "Family members",
        whosinthefamily: "Who does the household consist of?",
        helptext: {
          button: "What is a household?",
          section1:
            "This tool was made to help you get an overview of your personal finances. Your finances often relies on more people than yourself, such as a partner or kids. How many people are a part of your household determines how much money is spent on for example food per month.",
          section2:
            "That is why we define a household as the people you live with and you share your finances with.",
          section3:
            "For example: If you have 2 kids and a partner you live with, this is your household. If one of your kids move out and become financially independent of you, they are no longer a part of your household, even though they are a part of your family.",
        },
        name: "Name",
        gender: "Gender",
        age: "Age",
        year: "years",
        genderselections: {
          male: "Male",
          female: "Female",
          nonbinary: "Non-Binary",
          prefernot: "Prefer not to say",
        },
        optionals: {
          pregnant: "Pregnant",
          sfo: "AKS/SFO",
          student: "Student",
          kindergarden: "Kindergarten",
          freesfocore: "Free SFO core time",
        },
        sfo: {
          no: "No",
          fulltime: "Full time",
          parttime: "Part time",
        },
        addfamilymember: "Add family member",
        otherinfo: {
          header: "Other family information",
          helptext: {
            button: "Why is this relevant?",
            section1:
              "If you have one or more children in either kindergarten or AKS (SFO), we need the family's total annual income to calculate the amount you must pay per month.",
            section2:
              "Gross annual income is income you earn before tax. This sum can be found either in an employment contract or in the tax return, which is the income you report.",
            section3:
              "We use the number of electric cars and fossil cars to calculate the expected cost per car per month. A fossil car is a car that uses petrol or diesel as fuel.",
          },
          vehicles: "Vehicles",
          numberoffossilcars: "Number of fossil cars",
          yearlyincome: "Annual income before tax",
          numberofelectriccars: "Number of electric cars",
        },
        housing: {
          header: "Housing situation",
          own: "Own",
          rent: "Rent",
        },
        animals: {
          header: "Do you have any pets?",
          name: "Name",
          kind: "Pet",
          kindplaceholder: "Enter pet here (i.e. Horse)",
          addanimal: "Add pet",
        },
        savingsgoal: {
          header: "Does the family have a saving goal?",
          placeholder: "Enter goal here (i.e. Holiday trip)",
          amountplaceholder: "Approximate amount",
          helptext: {
            button: "Why is this relevant?",
            header: "What do we mean by saving goal?",
            section1:
              "Do you have a dream of buying a car, taking the family on holiday or something else that requires you to save money? Or do you simply want to save a little for the future?",
            section2: "Enter an amount that we can help you reach.",
          },
        },
      },
      moneyin: {
        header: "Incoming money",
        subheader: "Income and financial support",
        paragraph:
          "This is the income your household receive into your bank accounts each month, after taxes have been deducted.",
        helptext: {
          buttontext: "What if my income is irregular?",
          section1:
            "There can be many reasons for an irregular income. This is typical for sole proprietorship and freelance work.",
          section2:
            "If this applies to you, please state your income for the previous month. You can also calculate the average of a few months and enter it on the 1st of the month.",
          section3:
            "The main purpose of the survey is to get a better picture of how much you have in income and how much you have in expenses. For many, it will help to see when you receive money, so that you can schedule large payments right after these days. Therefore, the date can be useful. If it's not possible, then it's not possible, but the tool will still be able to give you a better overview of your finances.",
        },
        income: "Income",
        incomeplural: "Total income",
        addincome: "Add income",
        category: "Category",
        permonth: "per month",
        arrangement: "Arrangement",
        paymentdate: "Pay date",
        dayplaceholder: "Day",
        amount: "Amount",
        amountplaceholder: "i.e. 10 000",
        amountformaterror: "Please input a number",
        categories: {
          wage: "Salary",
          other: "Other income",
          otherincome: "Other income",
          parttime: "Part time income",
          privatefunding: "Private alemony",
          housingbenifit: "Husbanken",
          governmentincome: "NAV",
          socialsupport: "NAV-support",
          finassistance: "Financial assistance",
          childbenifit: "Child benefit",
          unemploymentbenifit: "Unemployment benefit",
          sicknessbenifit: "Sickness benefit",
          parentalallowance: "Parental allowance",
          cashforcarebenifit: "Cash-for-care benefit",
          pension: "Pension",
          workassessmentallowance: "Work assessment allowance",
          employschemebenifit: "Employment scheme benefits",
          disabilitypension: "Disability pension",
          qualificationbenifit: "Qualification benefit",
          transitionalbenifit: "Transitional benefit",
          childsupport: "Child support",
          childsupportadvance: "Child support advance",
          introductionallowance: "Introduction allowance",
          basicchildbenifit: "Basic benefit",
          assistanceallowance: "Assistance allowance",
          lumpsumgrant: "Lump sum grant",
          carebenifit: "Care benefit",
          childcarebenifit: "Child care benefit",
        },
        wage: "Salary",
        otherincome: "Other income",
        parttime: "Part time income",
        privatefunding: "Private alemony",
        housingbenifit: "Husbanken",
        governmentincome: "NAV",
        socialsupport: "NAV-support",
        benifitarragement: {
          finassistance: "Financial assistance",
          childbenifit: "Child benefit",
          unemploymentbenifit: "Unemployment benefit",
          sicknessbenifit: "Sickness benefit",
          parentalallowance: "Parental allowance",
          cashforcarebenifit: "Cash-for-care benefit",
          pension: "Pension",
          workassessmentallowance: "Work assessment allowance",
          employschemebenifit: "Employment scheme benefits",
          disabilitypension: "Disability pension",
          qualificationbenifit: "Qualification benefit",
          transitionalbenifit: "Transitional benefit",
          childsupport: "Child support",
          childsupportadvance: "Child support advance",
          introductionallowance: "Introduction allowance",
          basicchildbenifit: "Basic benefit",
          assistanceallowance: "Assistance allowance",
          lumpsumgrant: "Lump sum grant",
          carebenifit: "Care benefit",
          childcarebenifit: "Child care benefit",
        },
      },
      moneyout: {
        header: "Outgoing money",
        paragraph:
          "This is the monthly downpayment of debt your household pays each month.",
        debt: {
          header: "Debt",
          selectplaceholder: "Select type",
          helptext: {
            buttontext: "Do you need help finding yout total debt?",
            header: "Unsure of your own debt situation?",
            section1:
              "Below you will find the most common services to find out what debt you have. A good start is also to look for bills in the mail. Remember to check with your own bank as well.",
            section2:
              "Debt is money you owe that you have borrowed, e.g. home loans, car loans or private loans. Some people forget that credit card debt is also a loan. When you use a credit card, you borrow money from the bank and pay it back in the form of a bill.",
            howtofindheader: "How to find your debt?",
            howtofinddesc:
              "If you do not have an overview of your debt, you can use the list below to log in to the various services to get an overview. NB! Requires BankID.",
            services: {
              altinn: {
                desc: "Altinn - debts, credit balances and payments in and out between you and the tax collector",
                link: "https://www.altinn.no/en/forms-overview/tax-administration/my-claims-and-payments/",
              },
              sismo: {
                desc: "Statens innkrevingssentral - collecting claims from the government",
                link: "https://www.sismo.no/en/pub/",
              },
              norwdebt: {
                desc: "Norsk Gjeld - unsecured credit (Norwegian only)",
                link: "https://www.norskgjeld.no/",
              },
              inkassoreg: {
                desc: "Inkassoregisteret - contacts yor creditors on your behalf and provides an overview (Norwegian only)",
                link: "https://www.inkassoregisteret.com/",
              },
              digipost: {
                desc: "Digipost - bills and invoices could be sent to you digitally, if you have signed up to receive mail digitally.",
                link: "https://www.digipost.no/en",
              },
              lanekassen: {
                desc: "Lånekassen - Student grants and loans",
                link: "https://www.lanekassen.no/en-US/",
              },
            },
          },
          debt: "Debt type",
          interest: "Interest",
          duedate: "Due date each month",
          amount: "Amount",
          adddebt: "Add debt",
          totaldebt: "Total debt",
          dayplaceholder: "Day",
        },
        expenses: {
          header: "Expenses",
          helptextbutton:
            "Do you need help to understand the different categories?",
          selectplaceholder: "Select expense",
          categories: {
            foodAndDrink: "Food and drink",
            clothesAndFootwear: "Clothes and footwear",
            personalCare: "Personal care",
            toysSportMedia: "Toys, sports and media use",
            travelcost: "Travel",
            otherGroceries: "Other groceries",
            householdItems: "Household items",
            furniture: "Furniture",
            mediaAndLeisure: "Media use and leisure",
            car: "Car costs",
            kindergarden: "Kindergarten",
            sfo: "AKS/SFO",
            infantGear: "Infant equipment",
            mortgage: "Mortgage",
            rent: "Rent and other housing costs",
            electricity: "Electricity",
            insurance: "Insurance",
            otherDebt: "Other debt",
            carDebt: "Car loan",
            tax: "Pre collected tax",
            studentDebt: "Student debt",
            consumerDebt: "Unsecured credit debt",
            creditCardDebt: "Credit card debt",
            category22: "Savings",
            undefined: "UNDEFINED",
            other: "Annet",
          },
          helptext: {
            header: "Description of expense categories",
            foodAndDrink:
              "This expense covers food and drink from both grocery stores and restaurants.",
            clothesAndFootwear:
              "Expenses for clothes and shoes for different seasons, as well as clothes for regular sports and leisure activities and for formal occasions.",
            personalCare:
              "Personal care includes expenses for personal care, such as soap, simple dental care, cosmetics, fragrance, hairdresser, shaving supplies, diapers, etc. Dental check-up and GP are also included.",
            toysSportMedia:
              "Includes childrens toys, bicycles, sports equipment, laptops, books, computer games, cinema, theater etc. In addition, it covers participation in leisure activities and membership in voluntary organisations.",
            travelcost:
              "Expenses for public transport. Expenses in connection with a car can be found in 'Car costs'.",
            otherGroceries:
              "This expenditure includes stationery, washing and cleaning supplies, pharmaceuticals, food and household supplies and electrical supplies (light bulbs, batteries, etc.)",
            householdItems:
              "Expenses for appliances, kitchen equipment, table linen, cleaning equipment and the like, as well as household textiles such as bed linen, towels and curtains.",
            furniture:
              "This expense includes all furniture and fixtures in all rooms, such as the entrance hall, living room, kitchen and bedroom.",
            mediaAndLeisure:
              "Expenses for TV, radio, internet, channel package for TV, game console, speaker etc. Here are also some expenses for leisure equipment linked to the household, such as tents, portable stove and printers. The purchase of newspapers, magazines and simple home contents insurance is also included in this expense.",
            car: "This expense includes operating expenses, including insurance and annual fee, as well as expenses for toll passage.",
            infantGear:
              "Equipment that an infant needs from birth, e.g. bed, bed linen, pram, baby bottles and the like. In addition, equipment is included that is often acquired when the child has grown, such as, for example. high chair, children's cutlery, stroller, baby carrier and equipment for childproofing the home.",
            kindergarden: "Monthly total expenses for kindergarten.",
            sfo: "Monthly total expenses for AKS/SFO.",
            rent: "Rent and joint expenses, i.e. service charges etc.",
            electricity: "Your last power bill, including power grid fees.",
            insurance:
              "All monthly billed insurances, i.e. travel, housing, contents and disability insurance etc..",
            tax: "This expense item applies to those who have taxable income or wealth that is not subject to tax withholding, and thus advance tax is paid on it. This applies, for example, to people with sole proprietorships.",
          },
          expense: "Expense",
          amount: "Amount",
          addexpense: "Add expense",
          total: "Total expenses",
        },
      },
      result: {
        balance: {
          header: "Monthly result",
          moneyin: "Incoming money",
          moneyout: "Outgoing money",
          savingspotential: "Savings potential",
          overspending: "Overspending",
        },
        debt: {
          header: "Monthly debt payments",
          total: "Total",
        },
        expenses: {
          header: "Monthly expenses",
          runningcost: {
            header: "Budget adjustment",
            desc: "Below you can see your monthly expenses together with the estimated SIFO reference budget. You can use the sliders to adjust your financial budget to set a goal for next month's spending. Try to adjust your expenses to an amount that gives you room to save money.",
            placeholder: "Please add some expenses",
            helptext: {
              buttontext: "What is SIFO?",
              section1:
                "SIFO's reference budget shows general consumption expenditure for different types of households. The budget has been updated with figures for 2023.",
              section2:
                'For a detailed description of the various categories, see "Outgoing money".',
              subsection1: {
                header: "A reasonable standard of living",
                desc: "The reference budget presents the costs of maintaining a reasonable standard of living for the household of interest.A reasonable, or acceptable, standard of living assumes a consumption level that is generally accepted in Norwegian society. The level is intended to reflect the population’s perception of what a typical person or family should be able to consume if all adult household members participate in the workforce. It should neither reflect a luxury level nor be restricted to only cover basic needs. The consumption level allows for fulfilment of the public health and nutritional requirements and for satisfactory participation in the most common leisure activities.",
              },
              subsection2: {
                header: "The budget is an example",
                desc: "The reference budget is based on a detailed list of goods and services and their costs. It exemplifies what researchers within the various consumption areas consider to be a reasonable level of consumption. This means that the budget does not reflect a statistical average. The goods that form the basis for the calculations are of normal, sound quality, with the emphasis on durability, accessible design and functionality. If relevant, safety aspects have also been taken into account. Furthermore, it is worth noting that the budget does not cover all expenses a person or a family might have, such as housing, holiday travel, gifts and health services other than an annual visit to the general practitioner and the dentist.",
              },
              subsection3: {
                header: "The budget is a longterm budget",
                desc: "The reference budget is a supplementary budget for households that already have an acceptable standard of living. It is referred to as a long-term budget because it assumes that money is set aside monthly for more infrequent purchases of expensive and durable consumer goods. When the refrigerator, cooker or washing machine breaks down, money should be available to repair it or to buy a new one without affecting the consumption pattern.",
              },
            },
            reset: "Reset",
          },
        },
        monthlyoverspending: "Monthly overspending",
        monthlysavingpotential: "Monthly surplus",
        saving: {
          part1: "You can reach your saving goal: ",
          part2: ", in ",
          part3:
            " months, by saving your stated savings and your monthly surplus.",
          part3b: " months, by saving your monthly surplus.",
        },
      },
      theend: {
        header: "Congratulations!",
        summary:
          "You have taken an important step towards a better overview of your finances! If you would like a summary, you can download the report using the button below. The next time you use the website, the PDF can be uploaded, so you don't have to start over. Good luck!",
        finishanddownload: "Finish and download report",
      },
      pdf: {
        intro:
          "You will find your summary in this PDF. Save it in a safe location, and remember where it is.",
        instruction:
          "Upload this PDF on your next visit to the Economy Manager, to load your data.",
        description:
          "Your data is retrieved from this PDF, and you will not have to reenter all your inputs.",
        howtoreference:
          "You will find instructions on how to upload this PDF in the first step of the Economy Manager",
        budget: "Budget",
        adjustedexpenses: "Adjusted expenses",
        frontpage: {
          filename: "frontpage_pdf_en.png",
        },
      },
      age: {
        month0_5: "0-5 months",
        month6_11: "6-11 months",
        year1: "1 year",
        year2: "2 year",
        year3: "3 year",
        year4_5: "4 to 5 years",
        year6_9: "6 to 9 years",
        year10_13: "10 to 13 years",
        year14_17: "14 to 17 years",
        year18_19: "18 to 19 years",
        year20_30: "20 to 30 years",
        year31_50: "31 to 50 years",
        year51_60: "51 to 60 years",
        year61_66: "61 to 66 years",
        year67_73: "67 to 73 years",
        olderThan74: "74 years and older",
        unknown: "",
      },
    },
  },
  nb: {
    translation: {
      yes: "Ja",
      no: "Nei",
      goback: "Tilbake",
      gonext: "Neste",
      finish: "Fullfør",
      feedback: "Send oss din tilbakemelding",
      stepsbar: {
        family: {
          title: "Familie",
          subtitle: "Oversikt over familen",
          person: "person",
          persons: "personer",
        },
        in: {
          title: "Penger inn",
          subtitle: "Lønn og støtteordninger",
        },
        out: {
          title: "Penger ut",
          subtitle: "Gjeld og utgifter",
        },
        result: {
          title: "Resultat",
          subtitle: "Din økonomi",
        },
        nextstep: {
          title: "Neste steg",
          subtitle: "Tiltak og råd",
        },
      },
      home: {
        headername: "Økonomiveilederen",
        sloagan: "- for bedre oversikt og kontroll på økonomien",
        start: "Start ny kartlegging",
        what: {
          header: "Hva er Økonomiveilederen?",
          text: "Velkommen til økonomiveilederen! Målet med dette verktøyet er å gi deg bedre oversikt over din pengebruk. Ved å få bedre oversikt, tror vi at det blir enklere å ta nødvendige grep for å få en trygg økonomi og en enklere hverdag.",
          usage:
            "Bruk verktøyet til å legge inn husstandens inntekter og månedlige utgifter. Da vil du få en oversikt om du/dere har overforbruk eller potensiale for å spare.",
          usage2:
            "Det følger også med en enkel budsjettplanlegger, der man tar utgangspunkt i dine utgifter og visuelt kan eksperimentere med hvor man bør spare penger og hvordan det kan ha utslag på din økonomi på lengre sikt.",
        },
      },
      intro: {
        droppfile: "Slipp fil",
        donethisbefore: "Har du gjort dette før?",
        upladdesc:
          "Hvis du lastet ned en PDF forrige gang du gjorde dette, så kan du laste den opp igjen her. Da bruker vi den til å fylle ut feltene, så slipper du å fylle inn all informasjonen på nytt.",
        button: "Last opp PDF",
        buttonalt: "Last opp PDF fra forrige gjennomgang",
      },
      family: {
        familymembers: "Familiemedlemmer",
        whosinthefamily: "Hvem består husstanden av?",
        helptext: {
          button: "Hva er en husstand?",
          section1:
            "I denne løsningen ønsker vi å hjelpe deg med å få oversikt over egen økonomi. Økonomien din avhenger ofte av andre enn deg selv, som for eksempel om man har ektefelle, samboer eller barn. Hvor mange som er en del av husholdningen avgjør for eksempel hvor mye penger man bruker på utgifter som mat.",
          section2:
            "Derfor definerer vi en husstand som de du bor sammen med og som din økonomi deles med.",
          section3:
            "For eksempel: Har du 2 barn og en samboer som du bor med er dette din husstand. Dersom en av barna dine har flyttet ut og er økonomisk uavhengig av deg er dette barnet ikke en del av din husstand, selv om barnet er en del av din familie.",
        },
        name: "Navn",
        gender: "Kjønn",
        age: "Alder",
        year: "år",
        genderselections: {
          male: "Mann",
          female: "Kvinne",
          nonbinary: "Ikke-binær",
          prefernot: "Ønsker ikke oppgi",
        },
        optionals: {
          pregnant: "Gravid",
          sfo: "AKS/SFO",
          student: "Student",
          kindergarden: "Barnehage",
          freesfocore: "Gratis kjernetid SFO",
        },
        sfo: {
          no: "Nei",
          fulltime: "Heltid",
          parttime: "Deltid",
        },
        addfamilymember: "Legg til medlem",
        otherinfo: {
          header: "Annen familieinformasjon",
          helptext: {
            button: "Hvorfor spør vi om dette?",
            section1:
              "Hvis du har et eller flere barn i enten barnehage eller AKS (SFO) trenger vi familiens samlede årsinntekt for å beregne hvilken sum dere må betale per måned.",
            section2:
              "Brutto årsinntekt er inntekt du tjener før skatt. Denne summen kan man finne enten i en arbeidskontrakt eller i skattemeldingen, som er den inntekten man rapporterer.",
            section3:
              "Vi bruker antall elbiler og fossilbiler til å beregne forventet kostnad per bil per måned. En fossilbil er en bil som bruker bensin eller diesel som drivstoff.",
          },
          vehicles: "Kjøretøy",
          numberoffossilcars: "Antall fossilbiler i husstanden",
          yearlyincome: "Brutto årsinntekt",
          numberofelectriccars: "Antall elbiler i husstanden",
        },
        housing: {
          header: "Boligsituasjon",
          own: "Eie",
          rent: "Leie",
        },
        animals: {
          header: "Eier du noen dyr?",
          name: "Navn",
          kind: "Type dyr",
          kindplaceholder: "Skriv inn dyret her (f.eks Hest)",
          addanimal: "Legg til dyr",
        },
        savingsgoal: {
          header: "Har familien et sparemål?",
          placeholder: "Skriv inn målet her (f.eks. ferietur)",
          amountplaceholder: "ca beløp",
          helptext: {
            button: "Hvorfor spør vi om dette?",
            header: "Hva mener vi med sparemål?",
            section1:
              "Med sparemål så mener vi om du har noe du sparer til? Har du en drøm om å kjøpe en bil, ta med familien på ferie eller noe annet som krever at du må spare penger? Ønsker du ganske enkelt bare å spare litt til framtiden?",
            section2: "Legg inn et beløp som vi kan hjelpe deg å nå.",
          },
        },
      },
      moneyin: {
        header: "Penger inn",
        subheader: "Inntekt og annen støtte",
        paragraph:
          "Dette er inntektene din husstand mottar på bankkonto månedlig, etter at skatt er trukket fra.",
        helptext: {
          buttontext: "Hva om jeg ikke har inntekt på faste datoer?",
          section1:
            "Det kan være mange grunner til at man ikke har inntekt som kommer inn på en fast dato. Har man, for eksempel, et enkeltpersonforetak og fakturerer til ulike tider hver måned, så kan det være vanskelig å si.",
          section2:
            "Hvis dette gjelder deg, kan du ta utgangspunkt i forrige måned og se når du hadde inntekter da. Du kan også ta et gjennomsnitt av noen måneder og sette det på den første i måneden. Det er ikke så farlig når den føres inn, bare det gir et bilde av hvor mye inntekt man kan forvente å ha hver måned.",
          section3:
            "Hovedhensikten med kartleggingen er å få et bedre bilde av hvor mye man har i inntekt og hvor mye man har i utgifter. For mange vil det hjelpe å se når man får penger inn, slik at man kan sette store betalinger til rett etter disse dagene. Derfor kan det være nyttig med dato. Hvis det ikke er mulig, så er det ikke mulig, men verktøyet vil fortsatt kunne gi deg en bedre oversikt over økonomien.",
        },
        income: "Inntekt",
        incomeplural: "Totale inntekter",
        addincome: "Legg til inntekt",
        category: "Kategori",
        permonth: "pr. måned",
        arrangement: "Ordning",
        paymentdate: "Dato for utbetaling",
        dayplaceholder: "Dag",
        amount: "Beløp",
        amountplaceholder: "f.eks 10 000",
        amountformaterror: "Vennligst skriv inn et nummer",
        categories: {
          wage: "Lønn",
          otherincome: "Annen inntekt",
          other: "Annen inntekt",
          parttime: "Deltidsjobb",
          privatefunding: "Privat bidrag",
          housingbenifit: "Husbanken",
          governmentincome: "NAV",
          socialsupport: "NAV-støtte",
          finassistance: "Sosialhjelp",
          childbenifit: "Barnetrygd",
          unemploymentbenifit: "Dagpenger",
          sicknessbenifit: "Sykepenger",
          parentalallowance: "Foreldrepenger",
          cashforcarebenifit: "Kontantstøtte",
          pension: "Pensjon",
          workassessmentallowance: "Arbeidsavklaringspenger",
          employschemebenifit: "Tiltakspenger",
          disabilitypension: "Uførepensjon",
          qualificationbenifit: "Kvalifiseringsstønad",
          transitionalbenifit: "Overgangsstønad",
          childsupport: "Barnebidrag",
          childsupportadvance: "Bidragsforskudd",
          introductionallowance: "Introduksjonsstønad",
          basicchildbenifit: "Grunnstønad",
          assistanceallowance: "Hjelpestønad",
          lumpsumgrant: "Engangsstønad",
          carebenifit: "Omsorgsstønad",
          childcarebenifit: "Barnetilsyn",
        },
        wage: "Lønn",
        otherincome: "Annen inntekt",
        parttime: "Deltidsjobb",
        privatefunding: "Privat bidrag",
        housingbenifit: "Husbanken",
        governmentincome: "NAV",
        socialsupport: "NAV-støtte",
        benifitarragement: {
          finassistance: "Sosialhjelp",
          childbenifit: "Barnetrygd",
          unemploymentbenifit: "Dagpenger",
          sicknessbenifit: "Sykepenger",
          parentalallowance: "Foreldrepenger",
          cashforcarebenifit: "Kontantstøtte",
          pension: "Pensjon",
          workassessmentallowance: "Arbeidsavklaringspenger",
          employschemebenifit: "Tiltakspenger",
          disabilitypension: "Uførepensjon",
          qualificationbenifit: "Kvalifiseringsstønad",
          transitionalbenifit: "Overgangsstønad",
          childsupport: "Barnebidrag",
          childsupportadvance: "Bidragsforskudd",
          introductionallowance: "Introduksjonsstønad",
          basicchildbenifit: "Grunnstønad",
          assistanceallowance: "Hjelpestønad",
          lumpsumgrant: "Engangsstønad",
          carebenifit: "Omsorgsstønad",
          childcarebenifit: "Barnetilsyn",
        },
      },
      moneyout: {
        header: "Penger ut",
        paragraph:
          "Dette er den totale nedbetalingen av gjeld hver måned for din husstand.",
        debt: {
          header: "Gjeld",
          helptext: {
            buttontext: "Trenger du hjelp med å finne din samlede gjeld?",
            header: "Usikker på din egen gjeldssituasjon?",
            section1:
              "Under finner du de vanligste tjenestene for å finne ut hvilken gjeld du har. En god start er også å se etter regninger i posten. Husk å sjekke med din egen bank også.",
            section2:
              "Gjeld er penger du skylder som du har lånt, f.eks. huslån, billån eller private lån. Noen glemmer at kredittkortgjeld også er et lån. Når du bruker et kredittkort låner du penger av banken og betaler de tilbake i form av en regning.",
            howtofindheader: "Hvordan finner du din gjeld?",
            howtofinddesc:
              "Dersom du ikke har oversikt over din gjeld kan du bruke listen under for å logge deg på de forskjellige tjenestene for å få oversikt. NB! Krever BankID.",
            services: {
              altinn: {
                desc: "Altinn - krav og betalinger fra Skatteetaten",
                link: "https://www.altinn.no/skjemaoversikt/skatteetaten/mine-krav-og-betalinger/",
              },
              sismo: {
                desc: "Statens innkrevingssentral - krav og betalinger fra staten",
                link: "https://www.sismo.no/en/pub/",
              },
              norwdebt: {
                desc: "Norsk Gjeld - sjekk din forbruksgjeld",
                link: "https://www.norskgjeld.no/",
              },
              inkassoreg: {
                desc: "Inkassoregisteret - inkassoregisteret tar kontakt med inkassoselskapene på dine vegne og lager en oversikt.",
                link: "https://www.inkassoregisteret.com/",
              },
              digipost: {
                desc: "Digipost - noen ganger sendes regninger digitalt, som kan være vanskelig å fange opp",
                link: "https://www.digipost.no/",
              },
              lanekassen: {
                desc: "Lånekassen - Studielån",
                link: "https://www.lanekassen.no",
              },
            },
          },
          debt: "Gjeld",
          duedate: "Dato for forfall hver mnd.",
          interest: "Rente",
          amount: "Beløp",
          adddebt: "Legg til gjeld",
          totaldebt: "Total gjeld",
          dayplaceholder: "Dag",
        },
        expenses: {
          header: "Utgifter",
          helptextbutton: "Trenger du hjelp med å forstå de ulike kategoriene?",
          selectplaceholder: "Velg utgift",
          categories: {
            foodAndDrink: "Mat og drikke",
            clothesAndFootwear: "Klær og sko",
            personalCare: "Personlig pleie",
            toysSportMedia: "Lek, sport og mediebruk",
            travelcost: "Reisekostnader",
            otherGroceries: "Andre dagligvarer",
            householdItems: "Husholdningsartikler",
            furniture: "Møbler",
            mediaAndLeisure: "Mediebruk og fritid",
            car: "Bilkostnader",
            kindergarden: "Barnehage",
            sfo: "AKS/SFO",
            infantGear: "Spedbarnsutstyr",
            mortgage: "Huslån",
            rent: "Husleie og andre bokostnader",
            electricity: "Strøm",
            insurance: "Forsikring",
            otherDebt: "Annen gjeld",
            carDebt: "Billån",
            tax: "Skatt",
            studentDebt: "Studielån",
            consumerDebt: "Forbrukslån",
            creditCardDebt: "Kredittkortgjeld",
            saving: "Sparing",
            undefined: "UNDEFINED",
            other: "Annet",
          },
          helptext: {
            header: "Utfyllende om de enkelte forbruksområdene",
            foodAndDrink:
              "Denne utgiften dekker mat og drikke både fra dagligvarebutikk og restaurant.",
            clothesAndFootwear:
              "Utgifter til klær og sko til ulike årstider, samt klær for vanlige sports- og fritidsaktiviteter og til formelle anledninger.",
            personalCare:
              "Personlig pleie inkluderer utgifter til personlig pleie, som såpe, enkel tannpleie, kosmetikk, godlukt, frisør, barbersaker, bleier m.m. Tannlegeettersyn og fastlege inngår også.",
            toysSportMedia:
              "Utgiften omfatter leker til barn, sykler, sportsutstyr og treningssenter, bærbar pc, bøker, dataspill, kino, teater osv. I tillegg dekker den organisasjoner.",
            travelcost:
              "Utgifter til kollektivtransport. Utgifter i forbindelse med bil finnes i 'Bilkostnader'.",
            otherGroceries:
              "Denne utgiften omfatter papirvarer, vask- og rengjøringsartikler, farmasivarer, mat- og husholdningsartikler og el-artikler (lyspærer, batterier osv.)",
            householdItems:
              "Utgifter til hvitevarer, kjøkkenutstyr, dekketøy, rengjøringsutstyr og lignende, samt husholdstekstiler som sengetøy, håndklær og gardiner.",
            furniture:
              "Denne utgiften omfatter alt av møbler og inventar i alle rom, som entré, stue, kjøkken og soverom.",
            mediaAndLeisure:
              "Utgifter til TV, radio, internett, kanalpakke til TV og TV-abonnement som for eksempel Netflix, spillkonsoll, høyttaler m.m. Her er også noen utgifter til fritidsutstyr som er knyttet til husholdet, som telt, primus og printer. Også kjøp av aviser, magasiner og en enkel innboforsikring ligger inn i denne utgiften.",
            car: "Denne utgiften omfatter driftsutgifter, inkludert forsikring og årsavgift, samt utgifter til bompassering.",
            infantGear:
              "Utstyr som et barn trenger fra det er født, f.eks. seng, sengetøy, vogn, tåteflasker og lignende. I tillegg inkluderes utstyr som gjerne anskaffes når barnet har blitt større som for eks. barnestol, barnebestikk, trille, bæremeis og ustyr til barnesikring av hjemmet.",
            kindergarden: "Månedlig total barnehageutgift.",
            sfo: "Månedlig total utgift for aktivitetsskole/skolefritidsordning.",
            rent: "Utgifter som leie, fellesutgifter og kommunale avgifter.",
            electricity:
              "Siste månedlige strømregning og nettleie for husstanden.",
            insurance:
              "Denne posten gjelder alle forsikringer som betales månedlig, som for eksempel reiseforsikring, husforsikring, innboforsikring og uføreforsikring.",
            tax: "Denne utgiftsposten gjelder betaling av skatt, enten det er skattepliktig inntekt eller formue som det ikke blir trukket skatt av, og som det dermed betales forskuddsskatt på, eller eiendomsskatt og andre typer skatt. Forskuddsskatt gjelder for eksempel mennesker med enkeltmannsforetak.",
          },
          expense: "Utgift",
          amount: "Beløp",
          addexpense: "Legg til utgift",
          total: "Totalt utgifter",
        },
      },
      result: {
        balance: {
          header: "Månedsresultat",
          moneyin: "Penger inn",
          moneyout: "Penger ut",
          savingspotential: "Potensiell sparing",
          overspending: "Overforbruk",
        },
        debt: {
          header: "Månedlig gjeldsoversikt",
          total: "Total",
        },
        expenses: {
          header: "Månedlige utgifter",
          runningcost: {
            header: "Løpende utgifter",
            desc: "Under kan du se dine månedlige utgifter sammen med estimert SIFO referansebudsjett. Du kan bruke tabellen til å tilpasse den økonomiske balansen for å sette et mål for neste måneds pengebruk. Prøv å justere utgiftene til et beløp som gir deg rom til å spare penger.",
            placeholder: "Vennligst legg til noen utgifter",
            helptext: {
              buttontext: "Hva er SIFO?",
              section1:
                "SIFOs referansebudsjett viser alminnelige forbruksutgifter for ulike typer hushold. Budsjettet er oppdatert med tall for 2023.",
              section2:
                'For detaljert beskrivelse av de ulike kategoriene, se "Penger ut".',
              subsection1: {
                header: "Et akseptabelt forbruksnivå",
                desc: "Referansebudsjettet viser hva det koster å leve på et akseptabelt forbruksnivå for det aktuelle husholdet. Med et akseptabelt forbruksnivå menes et forbruk som kan godtas av folk flest. Forbruket er akseptabelt i den forstand at de fleste nordmenn - uansett egen inntekt - kan leve på dette nivået uten å skille seg for mye ut. Dette er et forbruk som en med rimelighet kan forvente at personer som er i arbeid har tilgang til. Det gjør det mulig å følge offentlige helse- og kostholdsanbefalinger og å delta i de mest vanlige fritidsaktivitetene på en fullverdig måte.",
              },
              subsection2: {
                header: "Budsjettet er et eksempel",
                desc: "Utgangspunktet for budsjettet er en detaljert oversikt over varer og tjenester som blir gjenstand for prismålinger. Det er et eksempel på hva fagfolk innen de ulike forbruksområdene mener inngår i et akseptabelt forbruk. Dette betyr at budsjettet ikke gjenspeiler et statistisk gjennomsnitt. Varene som ligger til grunn for beregningene holder et alminnelig bra kvalitetsnivå. Det er lagt vekt på god holdbarhet, enkel utførelse og funksjonalitet. I de tilfeller der det er nødvendig, er sikkerhetsaspektet også vurdert. Videre er det viktig å merke seg at budsjettet ikke omfatter alle utgifter som en person eller en familie kan ha, som f.eks. bolig, feriereiser, gaver og helsetjenester utover et årlig lege- og tannlegebesøk.",
              },
              subsection3: {
                header: "Budsjettet er et langtidsbudsjett",
                desc: "Referansebudsjettet er et budsjett for hushold som allerede lever på et akseptabelt nivå, det er et suppleringsbudsjett. Det blir kalt et langtidsbudsjett fordi det er beregnet at en skal sette til side penger hver måned for å ha råd til å foreta sjeldnere innkjøp av dyre og varige forbruksgjenstander. Når kjøleskapet, komfyren, vaskemaskinen eller lignende går i stykker, skal en ha penger til reparasjoner eller til å kjøpe nytt, uten at dette går ut over forbruket ellers.",
              },
            },
            reset: "Tilbakestill",
          },
        },
        monthlyoverspending: "Månedlig overforbruk",
        monthlysavingpotential: "Månedlig overskudd",
        saving: {
          part1: "Sparemålet: ",
          part2: ", kan nås om ",
          part3:
            " måneder, ved å spare din oppgitte sparesum og i tillegg spare ditt månedlige overskudd.",
          part3b: " måneder, ved å spare ditt overskudd hver måned.",
        },
      },
      nextsteps: {
        task: "Gjøremål",
        read_why: "Les hvorfor",
        read_how: "Les hvordan",
        how: "Hvordan?",
        source: "Kilde: ",
        source_1: "DNB",
        income: {
          header: "Tiltak for å øke inntekter",
          benefits: {
            title: "Økonomiske støtteordninger",
            subtitle:
              "Mange synes det er flaut å søke om økonomisk støtte slev om de har krav på det. Mange vet heller ikke om mulighetene eller hvordan de skal gå frem. Derfor har vi laget en oversikt over de mest relevante støtteordningene.",
            steps: {
              1: "Hvis noen av barna går på fritidsaktiviteter kan du søke om støtte til medlemskap og kontingent, utstyr, cuper og lignende via klubben. Send en mail til lagleder, så skal vedkommende ta dette videre slik at du får hjelp. Bor du i Oslo kan du også søke hjelp hos ",
              1_1: "Aktivitetsguiden",
              1_2: ". For Oslo og resten av landet kan man også finne ordninger via ",
              1_3: "UngFritid.",
              2: "Hvis skoleaktiviteter som turer, skikurs eller lignende koster penger, kan du søke om støtte via skolen. Send mail til skolen, læreren eller rektor.",
              3: "Trenger du bostøtte, ",
              3_1: "sjekk og søk her",
              3_2: " (Husbanken)",
              4: "Trenger du støtte til barnehage, ",
              4_1: "sjekk om du kan få det",
              4_2: " (udir.no) og så via egen kommune.",
              5: "Barn som skal begynne på, eller går på videregående skole, kan ha krav på ",
              5_1: "inntektsavhengig stipend (lanekassen.no)",
              5_2: ". De fleste elever på videregående skoler kan uansett få ",
              5_3: "utstyrsstipend.",
              6: "NAV har også støtteordninger:",
              6_1: "Helt eller delvis alene med barn? (nav.no)",
              6_2: "Økonomisk sosialhjelp (nav.no)",
            },
          },
          tax: {
            title: "Sjekk at du betaler riktig skatt",
          },
          rent: {
            title: "Lei ut eiendelene dine",
          },
          salary: {
            title: "Tørr å lønnsforhandle",
          },
          jobs: {
            title: "Ta småjobber",
          },
        },
        expenses: {
          header: "Tiltak for å senke utgifter",
        },
        debt: {
          header: "Tiltak for å senke gjeld",
        },
      },
      theend: {
        header: "Gratulerer!",
        summary:
          "Du har tatt et viktig steg mot bedre oversikt over din økonomi! Hvis du ønsker et sammendrag, kan du laste ned rapporten her. Neste gang du bruker nettsiden kan den lastes opp igjen, slik at du slipper å skrive inn alt på nytt. Lykke til videre!",
        finishanddownload: "Fullfør og last ned rapport",
      },
      pdf: {
        intro:
          "Denne PDFen inneholder oppsummeringen din. Derfor er det lurt å lagre den et sted du husker.",
        instruction:
          "Neste gang du skal inn og bruke nettsiden, kan du bruke PDFen til å laste inn dine data.",
        description:
          "Da henter vi inn alt du har kartlagt, slik at du slipper å gjøre alt på nytt.",
        howtoreference:
          "Instruksjoner om hvordan du gjør dette, finner du på første steg i Økonomiveilsederen.",
        budget: "Budsjett",
        adjustedexpenses: "Justerte utgifter",
        frontpage: {
          filename: "frontpage.png",
        },
      },
      age: {
        month0_5: "0-5 måneder",
        month6_11: "6-11 måneder",
        year1: "1 år",
        year2: "2 år",
        year3: "3 år",
        year4_5: "4 til 5 år",
        year6_9: "6 til 9 år",
        year10_13: "10 til 13 år",
        year14_17: "14 til 17 år",
        year18_19: "18 til 19 år",
        year20_30: "20 til 30 år",
        year31_50: "31 til 50 år",
        year51_60: "51 til 60 år",
        year61_66: "61 til 66 år",
        year67_73: "67 til 73 år",
        olderThan74: "eldre enn 74 år",
        unknown: "",
      },
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(detector)
  .init({
    debug: false,
    resources,
    // lng: "nb", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // // if you're using a language detector, do not define the lng option
    fallbackLng: "nb",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
