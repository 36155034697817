import { useEffect, useState } from "react";
import { Goal, LedgerRow, SifoCategories } from "../../models/models";
import styled from "styled-components";

import { ChartData } from "chart.js";

import {
  graphDataInitialState,
  PengerInnColour,
  PengerUtColour,
} from "../../chart/ChartSettings";
import {
  calculateMoneyIn,
  calculateMoneyOut,
  sortLedger,
} from "../../data/Ledger";

import _ from "lodash";

import { StyledBackgroundColour, StyledHeader } from "../../views/UserDetails";

import i18next from "i18next";
import { StepDefinition, StepsState } from "../../components/Steps";
import StepHeader from "../../components/StepHeader";
import ResultInteract from "../../components/ResultInteract";
import ResultDebt from "../../components/ResultDebt";
import ResultBalance from "../../components/ResultBalance";
import ComparisonGraph from "../../components/ComparisonGraph";

interface ResultProps {
  ledger: Array<LedgerRow>;
  removeLedgerRow: (id: string) => void;
  completeStep: () => void;
  goBack: () => void;
  goToStep: (step: StepDefinition) => void;
  goal: Goal;
  activeStep: StepDefinition | undefined;
  steps: StepsState;
  adjustments: Map<LedgerRowId, AdjustmentAmountPercent>;
  setAdjustments: (_: Map<LedgerRowId, AdjustmentAmountPercent>) => void;
  sifoNumbers: SifoCategories;
}

export type LedgerRowId = string;
export type AdjustmentAmountPercent = string;

export default function Result(props: ResultProps) {
  const {
    ledger,
    completeStep,
    activeStep,
    steps,
    goBack,
    adjustments,
    setAdjustments,
    goToStep,
    removeLedgerRow,
    goal,
    sifoNumbers,
  } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <StyledBackgroundColour>
      <StyledHeader>
        <StepHeader steps={steps} goToStep={goToStep} />
      </StyledHeader>
      <ResultBalance ledger={ledger} adjustments={adjustments} />
      <ResultDebt ledger={ledger} />

      <ResultInteract
        ledger={ledger}
        removeLedgerRow={removeLedgerRow}
        completeStep={completeStep}
        goBack={goBack}
        goToStep={goToStep}
        goal={goal}
        activeStep={activeStep}
        steps={steps}
        adjustments={adjustments}
        setAdjustments={setAdjustments}
        sifoNumbers={sifoNumbers}
      />
    </StyledBackgroundColour>
  );
}

export const StyledTotalDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold !important;
`;

export const DiffStyledDiv = styled.div`
  padding-top: 30px;

  .amountNegative {
    color: red !important;
  }

  hr {
    border-top: 1px solid #ccc;
  }
`;

const CenteredContentSection = styled.div`
  padding: 40px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGraphContainer = styled.div`
  height: 150px;
`;
