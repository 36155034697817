import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Grid,
  Icon,
  Input,
} from "semantic-ui-react";
import { LedgerRow, TransactionCategory } from "../../models/models";

import { calculateMoneyOut, sortLedger } from "../../data/Ledger";
import BackForwardControls from "../../components/BackForwardControls";
import { StyledBoxSection } from "../../components/StyledBoxSection";
import { StyledGrid, StyledGridRow, StyledGridRowBottom } from "../MoneyIn";
import { StepDefinition, StepsState } from "../../components/Steps";
import StepHeader from "../../components/StepHeader";
import styled from "styled-components";
import MoneyTotal from "../../components/MoneyTotal";
import HelpTextModalDebt from "../../modals/HelpTextModalDebt";
import TrashIcon from "../../components/TrashIcon";
import ErrorBar from "../../components/ErrorBar";
import { v4 as uuidv4 } from "uuid";
import HelpTextModalExpenses from "../../modals/HelpTextModalExpenses";
import i18next from "i18next";
import { forEach } from "lodash";

interface MoneyOutProps {
  ledger: Array<LedgerRow>;
  addLedgerRow: (_: LedgerRow) => void;
  editLedgerRow: (_: LedgerRow) => void;
  removeLedgerRow: (id: string) => void;
  completeStep: () => void;
  goBack: () => void;
  goToStep: (step: StepDefinition) => void;
  activeStep: StepDefinition | undefined;
  steps: StepsState;
  categories: Set<string>;
}
interface MoneyOutAndCategory {
  name: string;
  category: TransactionCategory;
}

interface DropDownItem {
  key: string;
  text: string;
  value: string;
}

const convertDropdownItem = (item: MoneyOutAndCategory): DropDownItem => {
  return {
    key: item.name,
    text: item.name,
    value: item.name,
  };
};

const convertDropdownItemVerbose = (
  key: string,
  text: string,
  value: string
): DropDownItem => {
  return {
    key: key,
    text: text,
    value: value,
  };
};

export default function MoneyOut(props: MoneyOutProps) {
  const [belopEndError, setBelopEndError] = useState<boolean>(false);
  const [sortedLedger, setSortedLedger] = useState<LedgerRow[]>([]);
  const [filteredLedger, setFilteredLedger] = useState<LedgerRow[]>([]);
  const [moneyOutDebt, setMoneyOutDebt] = useState<number>(0);
  const [moneyOutExpenses, setMoneyOutExpenses] = useState<number>(0);
  // const [graphData, setGraphData] = useState<ChartData<"bar", number[], unknown>>(graphDataInitialState);

  const [dropDownItemsDebt, setDropDownItemsDebt] = useState<DropDownItem[]>(
    []
  );
  const [dropDownItemsExpenses, setDropDownItemsExpenses] = useState<
    DropDownItem[]
  >([]);

  const [moneyOutItems, setMoneyOutItems] = useState<
    Map<string, MoneyOutAndCategory>
  >(new Map<string, MoneyOutAndCategory>());
  const {
    ledger,
    addLedgerRow,
    editLedgerRow,
    removeLedgerRow,
    completeStep,
    goBack,
    goToStep,
    steps,
    categories,
  } = props;

  useEffect(() => {
    const items = new Map<string, MoneyOutAndCategory>();
    items.set("foodAndDrink", {
      name: i18next.t<string>("moneyout.expenses.categories.foodAndDrink"),
      category: TransactionCategory.foodAndDrink,
    });
    items.set("clothesAndFootwear", {
      name: i18next.t<string>(
        "moneyout.expenses.categories.clothesAndFootwear"
      ),
      category: TransactionCategory.clothesAndFootwear,
    });
    items.set("personalCare", {
      name: i18next.t<string>("moneyout.expenses.categories.personalCare"),
      category: TransactionCategory.personalCare,
    });
    items.set("toysSportMedia", {
      name: i18next.t<string>("moneyout.expenses.categories.toysSportMedia"),
      category: TransactionCategory.toysSportMedia,
    });
    items.set("travelcost", {
      name: i18next.t<string>("moneyout.expenses.categories.travelcost"),
      category: TransactionCategory.travelcost,
    });
    items.set("infantGear", {
      name: i18next.t<string>("moneyout.expenses.categories.infantGear"),
      category: TransactionCategory.infantGear,
    });
    items.set("otherGroceries", {
      name: i18next.t<string>("moneyout.expenses.categories.otherGroceries"),
      category: TransactionCategory.otherGroceries,
    });
    items.set("householdItems", {
      name: i18next.t<string>("moneyout.expenses.categories.householdItems"),
      category: TransactionCategory.householdItems,
    });
    items.set("furniture", {
      name: i18next.t<string>("moneyout.expenses.categories.furniture"),
      category: TransactionCategory.furniture,
    });
    items.set("mediaAndLeisure", {
      name: i18next.t<string>("moneyout.expenses.categories.mediaAndLeisure"),
      category: TransactionCategory.mediaAndLeisure,
    });
    items.set("car", {
      name: i18next.t<string>("moneyout.expenses.categories.car"),
      category: TransactionCategory.car,
    });
    items.set("kindergarden", {
      name: i18next.t<string>("family.optionals.kindergarden"),
      category: TransactionCategory.kindergarden,
    });
    items.set("sfo", {
      name: i18next.t<string>("family.optionals.sfo"),
      category: TransactionCategory.sfo,
    });
    items.set("mortgage", {
      name: i18next.t<string>("moneyout.expenses.categories.mortgage"),
      category: TransactionCategory.mortgage,
    });
    items.set("Rent", {
      name: i18next.t<string>("moneyout.expenses.categories.rent"),
      category: TransactionCategory.rent,
    });
    items.set("electricity", {
      name: i18next.t<string>("moneyout.expenses.categories.electricity"),
      category: TransactionCategory.electricity,
    });
    items.set("insurance", {
      name: i18next.t<string>("moneyout.expenses.categories.insurance"),
      category: TransactionCategory.insurance,
    });
    items.set("saving", {
      name: i18next.t<string>("moneyout.expenses.categories.saving"),
      category: TransactionCategory.saving,
    });
    items.set("otherDebt", {
      name: i18next.t<string>("moneyout.expenses.categories.otherDebt"),
      category: TransactionCategory.otherDebt,
    });
    items.set("carDebt", {
      name: i18next.t<string>("moneyout.expenses.categories.carDebt"),
      category: TransactionCategory.carDebt,
    });
    items.set("tax", {
      name: i18next.t<string>("moneyout.expenses.categories.tax"),
      category: TransactionCategory.tax,
    });
    items.set("studentDebt", {
      name: i18next.t<string>("moneyout.expenses.categories.studentDebt"),
      category: TransactionCategory.studentDebt,
    });
    items.set("consumerDebt", {
      name: i18next.t<string>("moneyout.expenses.categories.consumerDebt"),
      category: TransactionCategory.consumerDebt,
    });
    items.set("creditCardDebt", {
      name: i18next.t<string>("moneyout.expenses.categories.creditCardDebt"),
      category: TransactionCategory.creditCardDebt,
    });

    setMoneyOutItems(items);
  }, []);

  useEffect(() => {
    const dropDownItemsDebt: DropDownItem[] = [];
    const dropDownItemsExpenses: DropDownItem[] = [];
    moneyOutItems.forEach((value) => {
      const i = convertDropdownItemVerbose(
        value.category,
        value.name,
        value.category
      );
      if (
        value.category === TransactionCategory.debt ||
        value.category === TransactionCategory.otherDebt ||
        value.category === TransactionCategory.mortgage ||
        value.category === TransactionCategory.carDebt ||
        value.category === TransactionCategory.studentDebt ||
        value.category === TransactionCategory.consumerDebt ||
        value.category === TransactionCategory.creditCardDebt
      ) {
        dropDownItemsDebt.push(i);
      } else {
        dropDownItemsExpenses.push(i);
      }
    });
    setDropDownItemsDebt(dropDownItemsDebt);
    setDropDownItemsExpenses(dropDownItemsExpenses);
  }, [moneyOutItems]);

  useEffect(() => {
    setSortedLedger(ledger);
  }, [ledger]);

  useEffect(() => {
    setFilteredLedger(
      sortedLedger.filter((row) => {
        return row.accountFrom === "user" && categories.has(row.category);
      })
    );
  }, [sortedLedger]);

  useEffect(() => {
    setMoneyOutDebt(
      calculateMoneyOut(
        filteredLedger.filter(
          (item) =>
            item.category === TransactionCategory.debt ||
            item.category === TransactionCategory.otherDebt ||
            item.category === TransactionCategory.mortgage ||
            item.category === TransactionCategory.carDebt ||
            item.category === TransactionCategory.studentDebt ||
            item.category === TransactionCategory.consumerDebt ||
            item.category === TransactionCategory.creditCardDebt
        )
      )
    );
    setMoneyOutExpenses(
      calculateMoneyOut(
        filteredLedger.filter(
          (item) =>
            item.category != TransactionCategory.debt &&
            item.category != TransactionCategory.otherDebt &&
            item.category != TransactionCategory.mortgage &&
            item.category != TransactionCategory.carDebt &&
            item.category != TransactionCategory.studentDebt &&
            item.category != TransactionCategory.consumerDebt &&
            item.category != TransactionCategory.creditCardDebt
        )
      )
    );
  }, [filteredLedger]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const getDateArray = () => {
    const arr = [];
    for (let i = 1; i <= 31; i++) {
      arr.push({
        key: i,
        text: i.toString(),
        value: i,
      });
    }
    return arr;
  };

  const DayCategories = getDateArray();

  let belopError = false;
  const validateInput = (inputvalue: string) => {
    const validNumber = RegExp(/^-?\d*(\.\d+)?$/);
    validNumber.test(inputvalue) ? (belopError = false) : (belopError = true);
  };

  return (
    <StyledBackgroundColour>
      <StyledHeader>
        <StepHeader steps={steps} goToStep={goToStep} />
      </StyledHeader>
      <StyledContainer>
        <StyledContainerSpace>
          <StyledDiv>
            <h1>
              <strong>{i18next.t<string>("moneyout.header")}</strong>
            </h1>
            <h2>
              <strong>{i18next.t<string>("moneyout.debt.header")}</strong>
            </h2>
            <p>{i18next.t<string>("moneyout.paragraph")}</p>

            <HelpTextModalDebt />
          </StyledDiv>
          <StyledBoxSection>
            <StyledGrid>
              {filteredLedger.filter(
                (item) => item.category === TransactionCategory.debt
              ).length > 0 && (
                <Grid.Row>
                  <Grid.Column width={5}>
                    <strong>{i18next.t<string>("moneyout.debt.debt")}</strong>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <strong>
                      {i18next.t<string>("moneyout.debt.interest")}
                    </strong>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <strong>
                      {i18next.t<string>("moneyout.debt.duedate")}
                    </strong>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <strong>{i18next.t<string>("moneyout.debt.amount")}</strong>
                  </Grid.Column>
                  {/* <Grid.Column width={3}>
                            <strong>Day of month</strong>
                        </Grid.Column> */}
                </Grid.Row>
              )}
              {filteredLedger
                .filter((item) => item.category === TransactionCategory.debt)
                .map((row) => {
                  return (
                    <StyledGridRow key={row.id}>
                      <Grid.Column width={5}>
                        <Dropdown
                          fluid
                          search
                          selection
                          placeholder={i18next.t<string>(
                            "moneyout.expenses.selectplaceholder"
                          )}
                          options={dropDownItemsDebt}
                          onChange={(_, data) => {
                            editLedgerRow({
                              ...row,
                              accountTo: data.value?.toString() as string,
                            });
                          }}
                          value={row.accountTo}
                        />
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Input
                          fluid
                          placeholder="f.eks 8"
                          onChange={(_, data) => {
                            //validateInput(data.value);
                            editLedgerRow({
                              ...row,
                              interest: data?.value,
                            });
                          }}
                          value={row.interest}
                          label={{ basic: true, content: "%" }}
                          labelPosition="right"
                        />
                        {belopError || belopEndError ? (
                          <ErrorBar msg="Vennligst skriv inn et nummer" />
                        ) : (
                          ""
                        )}
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Dropdown
                          search
                          selection
                          fluid
                          placeholder={i18next.t<string>(
                            "moneyout.debt.dayplaceholder"
                          )}
                          options={DayCategories}
                          onChange={(_, data) => {
                            editLedgerRow({
                              ...row,
                              dayOfMonth: parseInt(
                                data.value?.toString() || "0",
                                10
                              ),
                            });
                          }}
                          value={row.dayOfMonth}
                        />
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Input
                          fluid
                          placeholder="f.eks 10 000"
                          onChange={(_, data) => {
                            validateInput(data.value);
                            editLedgerRow({
                              ...row,
                              amount: parseInt(
                                data.value?.toString() || "0",
                                10
                              ),
                            });
                          }}
                          value={row.amount}
                          label={{ basic: true, content: "kr" }}
                          labelPosition="right"
                        />
                        {belopError || belopEndError ? (
                          <ErrorBar msg="Vennligst skriv inn et nummer" />
                        ) : (
                          ""
                        )}
                      </Grid.Column>
                      <Grid.Column
                        verticalAlign="middle"
                        textAlign="center"
                        width={1}
                      >
                        <TrashIcon
                          onClick={removeLedgerRow}
                          color="blue"
                          itemId={row.id}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                  );
                })}
              <StyledGridRowBottom>
                <Grid.Column width={16}>
                  <Button
                    circular
                    color="blue"
                    onClick={() => {
                      addLedgerRow({
                        id: uuidv4(),
                        dayOfMonth: 0,
                        amount: 0,
                        accountFrom: "user",
                        accountTo: "",
                        category: TransactionCategory.debt,
                      });
                    }}
                  >
                    <Icon name="plus" />
                    {i18next.t<string>("moneyout.debt.adddebt")}
                  </Button>
                </Grid.Column>
              </StyledGridRowBottom>

              <MoneyTotal
                text={i18next.t<string>("moneyout.debt.totaldebt")}
                total={moneyOutDebt}
              />
            </StyledGrid>
          </StyledBoxSection>
        </StyledContainerSpace>
      </StyledContainer>
      <StyledContainer>
        <StyledContainerSpace>
          <StyledDiv>
            <StyledSpace>
              <h2>
                <strong>{i18next.t<string>("moneyout.expenses.header")}</strong>
              </h2>
              <HelpTextModalExpenses />
            </StyledSpace>
          </StyledDiv>
          <StyledBoxSection>
            <StyledGrid>
              {filteredLedger.filter(
                (item) => item.category != TransactionCategory.debt
              ).length > 0 && (
                <Grid.Row>
                  <Grid.Column width={10}>
                    <strong>
                      {i18next.t<string>("moneyout.expenses.expense")}
                    </strong>
                  </Grid.Column>

                  <Grid.Column width={5}>
                    <strong>
                      {i18next.t<string>("moneyout.expenses.amount")}
                    </strong>
                  </Grid.Column>
                </Grid.Row>
              )}
              {filteredLedger
                .filter(
                  (item) =>
                    item.category != TransactionCategory.debt &&
                    item.category != TransactionCategory.mortgage &&
                    item.category != TransactionCategory.carDebt &&
                    item.category != TransactionCategory.consumerDebt &&
                    item.category != TransactionCategory.creditCardDebt &&
                    item.category != TransactionCategory.studentDebt &&
                    item.category != TransactionCategory.otherDebt
                )
                .map((row) => {
                  return (
                    <StyledGridRow key={row.id}>
                      <Grid.Column width={10}>
                        <Dropdown
                          search
                          selection
                          fluid
                          placeholder={i18next.t<string>(
                            "moneyout.expenses.selectplaceholder"
                          )}
                          options={dropDownItemsExpenses}
                          onChange={(_, data) => {
                            editLedgerRow({
                              ...row,
                              accountTo: data.value?.toString() as string,
                              category:
                                data.value?.toString() as TransactionCategory,
                            });
                          }}
                          value={row.accountTo}
                        />
                      </Grid.Column>
                      <Grid.Column width={5}>
                        <Input
                          fluid
                          placeholder="f.eks 10 000"
                          onChange={(_, data) => {
                            validateInput(data.value);
                            editLedgerRow({
                              ...row,
                              amount: parseInt(
                                data.value?.toString() || "0",
                                10
                              ),
                            });
                          }}
                          label={{ basic: true, content: "kr" }}
                          labelPosition="right"
                          value={row.amount}
                        />
                        {belopError || belopEndError ? (
                          <ErrorBar msg="Vennligst skriv inn et nummer" />
                        ) : (
                          ""
                        )}
                      </Grid.Column>
                      {/* <Grid.Column width={3}>{row.dayOfMonth}</Grid.Column> */}
                      <Grid.Column
                        verticalAlign="middle"
                        textAlign="center"
                        width={1}
                      >
                        <TrashIcon
                          onClick={removeLedgerRow}
                          color="blue"
                          itemId={row.id}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                  );
                })}
              <StyledGridRowBottom>
                <Grid.Column width={16}>
                  <Button
                    circular
                    color="blue"
                    onClick={() => {
                      addLedgerRow({
                        id: uuidv4(),
                        dayOfMonth: 0,
                        amount: 0,
                        accountFrom: "user",
                        accountTo: "",
                        category: TransactionCategory.undefined,
                      });
                    }}
                  >
                    <Icon name="plus" />
                    {i18next.t<string>("moneyout.expenses.addexpense")}
                  </Button>
                </Grid.Column>
              </StyledGridRowBottom>

              <MoneyTotal
                text={i18next.t<string>("moneyout.expenses.total")}
                total={moneyOutExpenses}
              />
            </StyledGrid>
          </StyledBoxSection>

          <BackForwardControls
            goBack={() => goBack()}
            completeStep={completeStep}
          />
        </StyledContainerSpace>
      </StyledContainer>
    </StyledBackgroundColour>
  );
}

// const StyledGraphContainer = styled.div`
//     height: 100px;
// `
const StyledBackgroundColour = styled.div`
  background-color: #f1f8f8;
  width: 100%;
`;

const StyledContainerSpace = styled.div`
  padding-top: 3em;
  padding-bottom: 4em;
`;
const StyledHeader = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledDiv = styled.div`
  position: relative;
`;

const StyledSpace = styled.div`
  padding-bottom: 2em;
`;
