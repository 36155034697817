import styled from "styled-components";
import { SliderComp } from "../Slider";
import {
  LedgerRow,
  SifoCategories,
  TransactionCategory,
} from "../../models/models";
import { AdjustmentAmountPercent } from "../ResultInteract";
import { Label } from "semantic-ui-react";
import i18next from "i18next";

export interface MoneyOutListProps {
  moneyOut: LedgerRow[];
  adjustments: Map<string, AdjustmentAmountPercent>;
  onUpdateValue: (id: string, value: string) => void;
  sifoNumbers: SifoCategories;
}

const getSifoValue = (accountTo: string, sifoNumbers: SifoCategories) => {
  if (accountTo === "clothesAndFootwear") {
    return sifoNumbers["clothesAndFootwear"];
  } else if (accountTo === "sfo") {
    return sifoNumbers["sfo"];
  } else if (accountTo === "foodAndDrink") {
    return sifoNumbers["foodAndDrink"];
  } else if (accountTo === "personalCare") {
    return sifoNumbers["personalCare"];
  } else if (accountTo === "toysSportMedia") {
    return sifoNumbers["toysSportMedia"];
  } else if (accountTo === "travelcost") {
    return sifoNumbers["travelcost"];
  } else if (accountTo === "infantGear") {
    return sifoNumbers["infantGear"];
  } else if (accountTo === "otherGroceries") {
    return sifoNumbers["otherGroceries"];
  } else if (accountTo === "householdItems") {
    return sifoNumbers["householdItems"];
  } else if (accountTo === "furniture") {
    return sifoNumbers["furniture"];
  } else if (accountTo === "mediaAndLeisure") {
    return sifoNumbers["mediaAndLeisure"];
  } else if (accountTo === "car") {
    return sifoNumbers["car"];
  } else if (accountTo === "kindergarden") {
    return sifoNumbers["kindergarden"];
  } else {
    return -1;
  }
};

export function MoneyOutList(props: MoneyOutListProps) {
  const { moneyOut, onUpdateValue, adjustments, sifoNumbers } = props;

  if (moneyOut.length === 0) {
    return (
      <div>{i18next.t<string>("result.expenses.runningcost.placeholder")}</div>
    );
  }

  return (
    <OuterBox>
      {moneyOut.map((row) => {
        const adhustmentStr = adjustments.get(row.id) || row.amount.toString();
        const adjustment = parseInt(adhustmentStr);

        return (
          <div key={`moneyout_${row.id}`}>
            <MoneyOutItemBox>
              <AmountDiv>{adjustment} kr</AmountDiv>
              <TitleDiv>
                {i18next.t<string>(
                  "moneyout.expenses.categories." + row.accountTo
                )}
              </TitleDiv>
              <SliderDiv>
                <SliderComp
                  id={row.id}
                  onUpdateValue={onUpdateValue}
                  maxPercent={"120"}
                  originalValue={row.amount}
                  value={adhustmentStr}
                  sifoValue={getSifoValue(row.accountTo, sifoNumbers)}
                />
              </SliderDiv>
            </MoneyOutItemBox>
          </div>
        );
      })}
    </OuterBox>
  );
}

const OuterBox = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #cfe3e3;
`;

const MoneyOutItemBox = styled.div`
  height: 8rem;
  margin: 5px;
  background: #f1f8f8;
  border: 1px dashed #cfe3e3;
`;

const AmountDiv = styled.div`
  float: right;
  position: relative;
  margin: 5px;
  padding-right: 0.5rem;
`;

const TitleDiv = styled.div`
  float: left;
  position: relative;
  margin: 5px;
`;

const SliderDiv = styled.div`
  float: left;
  position: relative;
  padding: 10px;
  clear: left;
  width: 100%;
`;
