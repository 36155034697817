import styled from "styled-components";
import {
  Image,
  Button,
  Header,
  Segment,
  TransitionablePortal,
  Icon,
} from "semantic-ui-react";
import { Fragment, useState } from "react";
import BankID from "./BankID.png";
import i18n from "../../i18n";
import i18next from "i18next";

export default function HelpTextModal() {
  const [open, setOpen] = useState<boolean>(false);

  const transition = {
    animation: "fly left",
    duration: 300,
  };

  return (
    <Fragment>
      <StyledSpace>
        <Button basic primary onClick={() => setOpen(true)}>
          {i18next.t<string>("moneyout.debt.helptext.buttontext")}
        </Button>
      </StyledSpace>
      <TransitionablePortal
        onClose={() => setOpen(false)}
        open={open}
        transition={transition}
      >
        <StyledSegment
          style={{
            right: "0",
            position: "fixed",
            top: "0",
            bottom: "0",
            zIndex: 1000,
          }}
        >
          <Button icon circular color="blue" onClick={() => setOpen(false)}>
            <Icon name="close" />
          </Button>
          <StyledModalSidebar>
            <StyledDiv>
              <Header>
                {i18next.t<string>("moneyout.debt.helptext.header")}
              </Header>
              <StyledParagraph>
                {i18next.t<string>("moneyout.debt.helptext.section1")}
              </StyledParagraph>
              <StyledParagraph>
                {i18next.t<string>("moneyout.debt.helptext.section2")}
              </StyledParagraph>
              <StyledGaryBox>
                <Header>
                  {i18next.t<string>("moneyout.debt.helptext.howtofindheader")}
                </Header>
                <StyledParagraph>
                  {i18next.t<string>("moneyout.debt.helptext.howtofinddesc")}
                </StyledParagraph>
                <StyledParagraph>
                  <StyledImage src={BankID} wrapped ui={false} />
                </StyledParagraph>
              </StyledGaryBox>
              <StyledLinkButton
                onClick={() =>
                  window.open(
                    i18next.t<string>(
                      "moneyout.debt.helptext.services.altinn.link"
                    )
                  )
                }
              >
                {i18next.t<string>(
                  "moneyout.debt.helptext.services.altinn.desc"
                )}
                <IconDiv>
                  <Icon name="external" />
                </IconDiv>
              </StyledLinkButton>
              <StyledLinkButton
                onClick={() =>
                  window.open(
                    i18next.t<string>(
                      "moneyout.debt.helptext.services.sismo.link"
                    )
                  )
                }
              >
                {i18next.t<string>(
                  "moneyout.debt.helptext.services.sismo.desc"
                )}
                <IconDiv>
                  <Icon name="external" />
                </IconDiv>
              </StyledLinkButton>
              <StyledLinkButton
                onClick={() =>
                  window.open(
                    i18next.t<string>(
                      "moneyout.debt.helptext.services.norwdebt.link"
                    )
                  )
                }
              >
                {i18next.t<string>(
                  "moneyout.debt.helptext.services.norwdebt.desc"
                )}
                <IconDiv>
                  <Icon name="external" />
                </IconDiv>
              </StyledLinkButton>
              <StyledLinkButton
                onClick={() =>
                  window.open(
                    i18next.t<string>(
                      "moneyout.debt.helptext.services.inkassoreg.link"
                    )
                  )
                }
              >
                {i18next.t<string>(
                  "moneyout.debt.helptext.services.inkassoreg.desc"
                )}
                <IconDiv>
                  <Icon name="external" />
                </IconDiv>
              </StyledLinkButton>

              <StyledLinkButton
                onClick={() =>
                  window.open(
                    i18next.t<string>(
                      "moneyout.debt.helptext.services.digipost.link"
                    )
                  )
                }
              >
                {i18next.t<string>(
                  "moneyout.debt.helptext.services.digipost.desc"
                )}
                <IconDiv>
                  <Icon name="external" />
                </IconDiv>
              </StyledLinkButton>
              <StyledLinkButton
                onClick={() =>
                  window.open(
                    i18next.t<string>(
                      "moneyout.debt.helptext.services.lanekassen.link"
                    )
                  )
                }
              >
                {i18next.t<string>(
                  "moneyout.debt.helptext.services.lanekassen.desc"
                )}
                <IconDiv>
                  <Icon name="external" />
                </IconDiv>
              </StyledLinkButton>
            </StyledDiv>
          </StyledModalSidebar>
        </StyledSegment>
      </TransitionablePortal>
    </Fragment>
  );
}

const StyledModalSidebar = styled.div`
  padding: 3em;
`;

const StyledDiv = styled.div`
  width: 40em;
`;

const StyledParagraph = styled.div`
  padding-top: 1em;
`;

const StyledSpace = styled.p`
  padding-top: 1em;
  padding-bottom: 1em;
`;

export const StyledGaryBox = styled.div`
  padding: 2em;
  background-color: #f5f5f0;
  border-radius: 0.25em;
  margin-top: 2em;
  margin-bottom: 2em;
`;
const StyledImage = styled(Image)`
  img {
    width: 100px !important;
  }
`;

const StyledSegment = styled(Segment)`
  margin-top: 0px !important;
  overflow: scroll;
`;

const StyledLinkButton = styled.button`
  border: 1px solid #a5c8d7;
  border-radius: 0.25em;
  background-color: #f1f8f8;
  padding: 1.5em;
  width: 100%;
  color: #3d8eb1;
  text-align: left;
  margin-bottom: 1em;
  &:hover {
    color: #ffffff;
    background-color: #a5c8d7;
  }
`;

const IconDiv = styled.div`
  float: right;
`;
