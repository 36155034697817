import styled from "styled-components";
import {
  Image,
  Button,
  Header,
  Segment,
  TransitionablePortal,
  Icon,
} from "semantic-ui-react";
import { Fragment, useState } from "react";
import i18next from "i18next";

export default function HelpTextModal() {
  const transition = {
    animation: "fly left",
    duration: 300,
  };
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <StyledSpace>
        <Button basic primary onClick={() => setOpen(true)}>
          {i18next.t<string>("moneyout.expenses.helptextbutton")}
        </Button>
      </StyledSpace>
      <TransitionablePortal
        onClose={() => setOpen(false)}
        open={open}
        transition={transition}
      >
        <StyledSegment
          style={{
            right: "0",
            position: "fixed",
            top: "0",
            bottom: "0",
            zIndex: 1000,
          }}
        >
          <Button icon circular color="blue" onClick={() => setOpen(false)}>
            <Icon name="close" />
          </Button>
          <StyledModalSidebar>
            <StyledDiv>
              <Header>
                {i18next.t<string>("moneyout.expenses.helptext.header")}
              </Header>
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.foodAndDrink"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.foodAndDrink")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.clothesAndFootwear"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>(
                "moneyout.expenses.helptext.clothesAndFootwear"
              )}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.personalCare"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.personalCare")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.toysSportMedia"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.toysSportMedia")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.travelcost")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.travelcost")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.otherGroceries"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.otherGroceries")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.householdItems"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.householdItems")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.furniture")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.furniture")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.mediaAndLeisure"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.mediaAndLeisure")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.car")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.car")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.infantGear")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.infantGear")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.kindergarden"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.kindergarden")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.sfo")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.sfo")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.rent")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.rent")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>(
                    "moneyout.expenses.categories.electricity"
                  )}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.electricity")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.insurance")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.insurance")}
              <StyledParagraph>
                <strong>
                  {i18next.t<string>("moneyout.expenses.categories.tax")}
                </strong>
              </StyledParagraph>
              {i18next.t<string>("moneyout.expenses.helptext.tax")}
              <StyledParagraph></StyledParagraph>
            </StyledDiv>
          </StyledModalSidebar>
        </StyledSegment>
      </TransitionablePortal>
    </Fragment>
  );
}

const StyledModalSidebar = styled.div`
  padding: 3em;
`;

const StyledDiv = styled.div`
  width: 40em;
`;

const StyledParagraph = styled.p`
  padding-top: 2em;
`;

const StyledSpace = styled.p`
  padding-top: 1em;
  padding-bottom: 1em;
`;

export const StyledGaryBox = styled.div`
  padding: 2em;
  background-color: #f5f5f0;
  border-radius: 0.25em;
  margin-top: 2em;
  margin-bottom: 2em;
`;

const StyledSegment = styled(Segment)`
  margin-top: 0px !important;
  overflow: scroll;
`;

const StyledLinkButton = styled.button`
  border: 1px solid #a5c8d7;
  border-radius: 0.25em;
  background-color: #f1f8f8;
  padding: 1.5em;
  width: 100%;
  color: #3d8eb1;
  text-align: left;
  margin-bottom: 1em;
  &:hover {
    color: #ffffff;
    background-color: #a5c8d7;
  }
`;

const IconDiv = styled.div`
  float: right;
`;
