import styled from "styled-components";
import {
  Image,
  Button,
  Header,
  Segment,
  TransitionablePortal,
  Icon,
} from "semantic-ui-react";
import { Fragment, useState } from "react";
import { LabelButton } from "../HelpTextModalGoal";
import i18next from "i18next";

export default function HelpTextModal() {
  const transition = {
    animation: "fly left",
    duration: 300,
  };
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <StyledSpace>
        <Button basic primary onClick={() => setOpen(true)}>
          {i18next.t<string>("result.expenses.runningcost.helptext.buttontext")}
        </Button>
      </StyledSpace>
      <TransitionablePortal
        onClose={() => setOpen(false)}
        open={open}
        transition={transition}
      >
        <StyledSegment
          style={{
            right: "0",
            position: "fixed",
            top: "0",
            bottom: "0",
            zIndex: 1000,
          }}
        >
          <Button icon circular color="blue" onClick={() => setOpen(false)}>
            <Icon name="close" />
          </Button>
          <StyledModalSidebar>
            <StyledDiv>
              <Header>
                {i18next.t<string>(
                  "result.expenses.runningcost.helptext.buttontext"
                )}
              </Header>
              <StyledParagraph>
                {i18next.t<string>(
                  "result.expenses.runningcost.helptext.section1"
                )}
              </StyledParagraph>

              <StyledParagraph>
                {i18next.t<string>(
                  "result.expenses.runningcost.helptext.section2"
                )}
              </StyledParagraph>
              <StyledGaryBox>
                <Header>
                  {i18next.t<string>(
                    "result.expenses.runningcost.helptext.subsection1.header"
                  )}
                </Header>
                <StyledParagraph>
                  {i18next.t<string>(
                    "result.expenses.runningcost.helptext.subsection1.desc"
                  )}
                </StyledParagraph>
              </StyledGaryBox>

              <StyledGaryBox>
                <Header>
                  {i18next.t<string>(
                    "result.expenses.runningcost.helptext.subsection2.header"
                  )}
                </Header>
                <StyledParagraph>
                  {i18next.t<string>(
                    "result.expenses.runningcost.helptext.subsection2.desc"
                  )}
                </StyledParagraph>
              </StyledGaryBox>
              <StyledGaryBox>
                <Header>
                  {i18next.t<string>(
                    "result.expenses.runningcost.helptext.subsection3.header"
                  )}
                </Header>
                <StyledParagraph>
                  {i18next.t<string>(
                    "result.expenses.runningcost.helptext.subsection3.desc"
                  )}
                </StyledParagraph>
              </StyledGaryBox>
            </StyledDiv>
          </StyledModalSidebar>
        </StyledSegment>
      </TransitionablePortal>
    </Fragment>
  );
}

const StyledModalSidebar = styled.div`
  padding: 3em;
`;

const StyledDiv = styled.div`
  width: 40em;
`;

const StyledParagraph = styled.p`
  padding-top: 1em;
`;

const StyledSpace = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
`;

export const StyledGaryBox = styled.div`
  padding: 2em;
  background-color: #f5f5f0;
  border-radius: 0.25em;
  margin-top: 2em;
  margin-bottom: 2em;
`;
const StyledImage = styled(Image)`
  img {
    width: 100px !important;
  }
`;

const StyledSegment = styled(Segment)`
  margin-top: 0px !important;
  overflow: scroll;
`;

const StyledLinkButton = styled.button`
  border: 1px solid #a5c8d7;
  border-radius: 0.25em;
  background-color: #f1f8f8;
  padding: 1.5em;
  width: 100%;
  color: #3d8eb1;
  text-align: left;
  margin-bottom: 1em;
  &:hover {
    color: #ffffff;
    background-color: #a5c8d7;
  }
`;

const IconDiv = styled.div`
  float: right;
`;
