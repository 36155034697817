import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Grid,
  Icon,
  Dropdown,
  Input,
} from "semantic-ui-react";
import { LedgerRow, TransactionCategory } from "../../models/models";

import { calculateMoneyIn, sortLedger } from "../../data/Ledger";
import { StyledBoxSection } from "../../components/StyledBoxSection";
import BackForwardControls from "../../components/BackForwardControls";
import styled from "styled-components";
import { StepDefinition, StepsState } from "../../components/Steps";
import StepHeader from "../../components/StepHeader";
import MoneyTotal from "../../components/MoneyTotal";
import TrashIcon from "../../components/TrashIcon";
import { v4 as uuidv4 } from "uuid";
import ErrorBar from "../../components/ErrorBar";
import HelpTextModal from "../../modals/HelpTextModalIncome";
import i18n from "../../i18n";
import i18next from "i18next";

interface MoneyInProps {
  ledger: Array<LedgerRow>;
  addLedgerRow: (_: LedgerRow) => void;
  editLedgerRow: (_: LedgerRow) => void;
  removeLedgerRow: (id: string) => void;
  completeStep: () => void;
  goBack: () => void;
  goToStep: (step: StepDefinition) => void;
  steps: StepsState;
}

interface MoneyInAndCategory {
  name: string;
  category: TransactionCategory;
}

interface DropDownItem {
  key: string;
  text: string;
  value: string;
}

export default function MoneyIn(props: MoneyInProps) {
  const [helptextModalOpen, setHelpTextModalOpen] = useState<boolean>(false);
  const [belopEndError, setBelopEndError] = useState<boolean>(false);
  const [subcategories, setSubcategories] = useState<Array<DropDownItem>>([]);
  const [dropDownItems, setDropDownItems] = useState<DropDownItem[]>([]);
  const [moneyInItems, setMoneyInItems] = useState<
    Map<string, MoneyInAndCategory>
  >(new Map<string, MoneyInAndCategory>());

  const [sortedLedger, setSortedLedger] = useState<LedgerRow[]>([]);
  const [filteredLedger, setFilteredLedger] = useState<LedgerRow[]>([]);
  const [moneyIn, setMoneyIn] = useState<number>(0);
  const {
    ledger,
    addLedgerRow,
    removeLedgerRow,
    completeStep,
    goBack,
    steps,
    goToStep,
    editLedgerRow,
  } = props;

  useEffect(() => {
    setSortedLedger(ledger);
  }, [ledger]);

  useEffect(() => {
    setFilteredLedger(
      sortedLedger.filter((row) => {
        return row.accountTo === "user";
      })
    );

    setMoneyIn(calculateMoneyIn(sortedLedger));
  }, [sortedLedger]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const Categories = [
    {
      key: TransactionCategory.wage,
      text: i18n.t<string>("moneyin.categories.wage"),
      value: TransactionCategory.wage,
    },
    {
      key: TransactionCategory.housingbenifit,
      text: i18n.t<string>("moneyin.categories.housingbenifit"),
      value: TransactionCategory.housingbenifit,
    },
    {
      key: TransactionCategory.governmentincome,
      text: i18n.t<string>("moneyin.categories.governmentincome"),
      value: TransactionCategory.governmentincome,
    },
    {
      key: TransactionCategory.otherincome,
      text: i18n.t<string>("moneyin.categories.otherincome"),
      value: TransactionCategory.otherincome,
    },
  ];

  const incomeTypes: Map<string, Array<DropDownItem>> = new Map<
    string,
    Array<DropDownItem>
  >();
  incomeTypes.set("housingbenifit", [
    {
      key: "housingbenifit",
      text: i18n.t<string>("moneyin.categories.housingbenifit"),
      value: "Husbanken",
    },
  ]);
  incomeTypes.set("wage", [
    {
      key: "wage",
      text: i18n.t<string>("moneyin.categories.wage"),
      value: "Lønn",
    },
  ]);
  incomeTypes.set("otherincome", [
    {
      key: "otherincome",
      text: i18n.t<string>("moneyin.categories.otherincome"),
      value: "Annen inntekt",
    },
  ]);

  incomeTypes.set("governmentincome", [
    {
      key: "finassistance",
      text: i18n.t<string>("moneyin.benifitarragement.finassistance"),
      value: "finassistance",
    },
    {
      key: "childbenifit",
      text: i18n.t<string>("moneyin.benifitarragement.childbenifit"),
      value: "childbenifit",
    },
    {
      key: "unemploymentbenifit",
      text: i18n.t<string>("moneyin.benifitarragement.unemploymentbenifit"),
      value: "unemploymentbenifit",
    },
    {
      key: "sicknessbenifit",
      text: i18n.t<string>("moneyin.benifitarragement.sicknessbenifit"),
      value: "sicknessbenifit",
    },
    {
      key: "parentalallowance",
      text: i18n.t<string>("moneyin.benifitarragement.parentalallowance"),
      value: "parentalallowance",
    },
    {
      key: "cashforcarebenifit",
      text: i18n.t<string>("moneyin.benifitarragement.cashforcarebenifit"),
      value: "cashforcarebenifit",
    },
    {
      key: "pension",
      text: i18n.t<string>("moneyin.benifitarragement.pension"),
      value: "pension",
    },
    {
      key: "workassessmentallowance",
      text: i18n.t<string>("moneyin.benifitarragement.workassessmentallowance"),
      value: "workassessmentallowance",
    },
    {
      key: "employschemebenifit",
      text: i18n.t<string>("moneyin.benifitarragement.employschemebenifit"),
      value: "employschemebenifit",
    },
    {
      key: "disabilitypension",
      text: i18n.t<string>("moneyin.benifitarragement.disabilitypension"),
      value: "disabilitypension",
    },
    {
      key: "qualificationbenifit",
      text: i18n.t<string>("moneyin.benifitarragement.qualificationbenifit"),
      value: "qualificationbenifit",
    },
    {
      key: "transitionalbenifit",
      text: i18n.t<string>("moneyin.benifitarragement.transitionalbenifit"),
      value: "transitionalbenifit",
    },
    {
      key: "childsupport",
      text: i18n.t<string>("moneyin.benifitarragement.childsupport"),
      value: "childsupport",
    },
    {
      key: "childsupportadvance",
      text: i18n.t<string>("moneyin.benifitarragement.childsupportadvance"),
      value: "childsupportadvance",
    },
    {
      key: "introductionallowance",
      text: i18n.t<string>("moneyin.benifitarragement.introductionallowance"),
      value: "introductionallowance",
    },
    {
      key: "basicchildbenifit",
      text: i18n.t<string>("moneyin.benifitarragement.basicchildbenifit"),
      value: "basicchildbenifit",
    },
    {
      key: "assistanceallowance",
      text: i18n.t<string>("moneyin.benifitarragement.assistanceallowance"),
      value: "assistanceallowance",
    },
    {
      key: "lumpsumgrant",
      text: i18n.t<string>("moneyin.benifitarragement.lumpsumgrant"),
      value: "lumpsumgrant",
    },
    {
      key: "carebenifit",
      text: i18n.t<string>("moneyin.benifitarragement.carebenifit"),
      value: "carebenifit",
    },
    {
      key: "childcarebenifit",
      text: i18n.t<string>("moneyin.benifitarragement.childcarebenifit"),
      value: "childcarebenifit",
    },
  ]);

  const getDateArray = () => {
    const arr = [];
    for (let i = 1; i <= 31; i++) {
      arr.push({
        key: i,
        text: i.toString(),
        value: i,
      });
    }
    return arr;
  };

  const DayCategories = getDateArray();

  let belopError = false;
  const validateInput = (inputvalue: string) => {
    const validNumber = RegExp(/^[0-9\b]+$/);
    validNumber.test(inputvalue) ? (belopError = false) : (belopError = true);
  };

  const convertDropdownItemVerbose = (
    key: string,
    text: string,
    value: string
  ): DropDownItem => {
    return {
      key: key,
      text: text,
      value: value,
    };
  };

  useEffect(() => {
    const dropDownItems: DropDownItem[] = [];
    moneyInItems.forEach((value) => {
      // const i = convertDropdownItem(value);
      const i = convertDropdownItemVerbose(
        value.category,
        value.name,
        value.category
      );
      dropDownItems.push(i);
    });
    setDropDownItems(dropDownItems);
  }, [moneyInItems]);

  const setCategory = (category: string | undefined, row: LedgerRow) => {
    if (category) {
      const items = incomeTypes.get(category);
      if (items) {
        if (items?.length === 1) {
          editLedgerRow({
            ...row,
            accountFrom: items[0].key,
            category:
              category === "wage"
                ? TransactionCategory.wage
                : category === "otherincome"
                ? TransactionCategory.otherincome
                : TransactionCategory.housingbenifit,
          });
        } else {
          editLedgerRow({
            ...row,
            category: TransactionCategory.governmentincome,
          });
          setSubcategories(items);
        }
      }
    }
  };

  // const getValueCategory = (row: LedgerRow) => {
  //   if (row.category === TransactionCategory.governmentincome) {
  //     return "NAV";
  //   } else if (row.category === TransactionCategory.wage) {
  //     return "Lønn";
  //   } else if (row.category === TransactionCategory.otherincome) {
  //     return "Annen inntekt";
  //   } else if (row.category === TransactionCategory.housingbenifit) {
  //     return "Husbanken";
  //   } else {
  //     return "";
  //   }
  // };

  // const getSubcategories = (row: LedgerRow) => {
  //   if (row.category === TransactionCategory.governmentincome) {
  //     const items = incomeTypes.get(row.category);
  //     if (items) {
  //       setSubcategories(items);
  //     }
  //   }
  // };

  useEffect(() => {
    const items = incomeTypes.get(TransactionCategory.governmentincome);
    if (items) {
      setSubcategories(items);
    }
  }, []);

  return (
    <StyledBackgroundColour>
      <StyledHeader>
        <StepHeader steps={steps} goToStep={goToStep} />
      </StyledHeader>
      <Container>
        <StyledContainerSpace>
          <h1>{i18next.t<string>("moneyin.header")}</h1>
          <h2>{i18next.t<string>("moneyin.subheader")}</h2>
          <p>{i18next.t<string>("moneyin.paragraph")}</p>
          <HelpTextModal />

          <StyledBoxSection>
            <StyledGrid>
              {filteredLedger.length > 0 && (
                <Grid.Row>
                  <StyledGridColumn width={3}>
                    <strong>{i18next.t<string>("moneyin.income")}</strong>
                  </StyledGridColumn>
                  <Grid.Column width={4}>
                    <strong>{i18next.t<string>("moneyin.arrangement")}</strong>
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <strong>{i18next.t<string>("moneyin.paymentdate")}</strong>
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <strong>{i18next.t<string>("moneyin.amount")}</strong>
                  </Grid.Column>
                </Grid.Row>
              )}
              {filteredLedger.map((row) => {
                if (row.accountTo === "user") {
                  return (
                    <StyledGridRow key={row.id}>
                      <StyledGridColumn width={3}>
                        <Dropdown
                          search
                          selection
                          placeholder={i18next.t<string>("moneyin.category")}
                          options={Categories}
                          onChange={(_, data) => {
                            setCategory(data.value?.toString(), row);
                          }}
                          value={row.category}
                        />
                      </StyledGridColumn>
                      <Grid.Column width={4}>
                        {row.category ===
                        TransactionCategory.governmentincome ? (
                          <Dropdown
                            search
                            selection
                            placeholder={i18next.t<string>(
                              "moneyin.arrangement"
                            )}
                            options={subcategories}
                            onChange={(_, data) => {
                              editLedgerRow({
                                ...row,
                                accountFrom: data.value?.toString() as string,
                              });
                            }}
                            value={row.accountFrom}
                          />
                        ) : (
                          <div>-</div>
                        )}
                      </Grid.Column>
                      <Grid.Column width={4}>
                        <Dropdown
                          search
                          selection
                          placeholder={i18next.t<string>(
                            "moneyin.dayplaceholder"
                          )}
                          options={DayCategories}
                          onChange={(_, data) => {
                            editLedgerRow({
                              ...row,
                              dayOfMonth: parseInt(
                                data.value?.toString() || "0",
                                10
                              ),
                            });
                          }}
                          value={row.dayOfMonth}
                        />
                      </Grid.Column>

                      <StyledGridColumn width={3}>
                        <Input
                          placeholder={i18next.t<string>(
                            "moneyin.amountplaceholder"
                          )}
                          onChange={(_, data) => {
                            validateInput(data.value);
                            editLedgerRow({
                              ...row,
                              amount: parseInt(
                                data.value?.toString() || "0",
                                10
                              ),
                            });
                          }}
                          value={row.amount}
                          label={{ basic: true, content: "kr" }}
                          labelPosition="right"
                        />
                        {belopError || belopEndError ? (
                          <ErrorBar
                            msg={i18next.t<string>("moneyin.amountformaterror")}
                          />
                        ) : (
                          ""
                        )}
                      </StyledGridColumn>

                      <Grid.Column
                        verticalAlign="middle"
                        textAlign="center"
                        width={1}
                      >
                        <TrashIcon
                          onClick={removeLedgerRow}
                          color="blue"
                          itemId={row.id}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                  );
                } else {
                  return null;
                }
              })}

              <StyledGridRowBottom>
                <Grid.Column width={16}>
                  <Button
                    circular
                    color="blue"
                    onClick={() => {
                      addLedgerRow({
                        id: uuidv4(),
                        dayOfMonth: 0,
                        amount: 0,
                        accountFrom: "",
                        accountTo: "user",
                        category: TransactionCategory.undefined,
                      });
                    }}
                  >
                    <Icon name="plus" />
                    {i18next.t<string>("moneyin.addincome")}
                  </Button>
                </Grid.Column>
              </StyledGridRowBottom>
              <MoneyTotal
                text={i18next.t<string>("moneyin.incomeplural")}
                total={moneyIn}
              />
            </StyledGrid>
          </StyledBoxSection>

          <BackForwardControls
            goBack={() => goBack()}
            completeStep={completeStep}
          />
        </StyledContainerSpace>
      </Container>
    </StyledBackgroundColour>
  );
}

export const StyledTotalGrid = styled(Grid)`
  background-color: #f5f5f0;
`;

export const StyledGridRowBottom = styled(Grid.Row)`
  text-align: center;
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
  text-align: left;
`;

export const StyledGridRow = styled(Grid.Row)`
  border: 1px solid #3d8eb1;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f1f8f8;
`;

const StyledBackgroundColour = styled.div`
  background-color: #f1f8f8;
  width: 100%;
`;

const StyledContainerSpace = styled.div`
  padding-top: 3em;
  padding-bottom: 4em;
`;
const StyledHeader = styled.div`
  background-color: #ffffff;
  width: 100%;
`;

const StyledGridColumn = styled(Grid.Column)`
  margin-right: 2rem;
`;
