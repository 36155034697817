import { useEffect, useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import { LedgerRow } from "../../models/models";
import styled from "styled-components";

import { ChartData } from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  chartOptions,
  graphDataInitialState,
  PengerInnColour,
  PengerUtColour,
} from "../../chart/ChartSettings";
import {
  calculateMoneyIn,
  calculateMoneyOut,
  sortLedger,
} from "../../data/Ledger";
import { StyledBoxSection } from "../StyledBoxSection";
import _ from "lodash";

import {
  StyledBackgroundColour,
  StyledContainerSpace,
} from "../../views/UserDetails";

import i18next from "i18next";

interface ResultBalanceProps {
  ledger: Array<LedgerRow>;
  adjustments: Map<LedgerRowId, AdjustmentAmountPercent>;
}

export type LedgerRowId = string;
export type AdjustmentAmountPercent = string;

export default function ResultBalance(props: ResultBalanceProps) {
  const [sortedLedger, setSortedLedger] = useState<LedgerRow[]>([]);
  const [inTotal, setInTotal] = useState<number>(0);
  const [outTotal, setOutTotal] = useState<number>(0);
  const [graphData, setGraphData] = useState<
    ChartData<"bar", number[], unknown>
  >(graphDataInitialState);
  const { ledger, adjustments } = props;

  const labels = [
    i18next.t<string>("result.balance.moneyin"),
    i18next.t<string>("result.balance.moneyout"),
  ];

  const computeInOutPercent = () => {
    const adjustedLedger = sortedLedger.map((row) => {
      if (adjustments.has(row.id)) {
        const adjustment = parseInt(adjustments.get(row.id) || "100");
        return {
          ...row,
          amount: adjustment,
        };
      } else {
        return row;
      }
    });

    const totalIn = calculateMoneyIn(adjustedLedger);
    setInTotal(totalIn);
    const totalOut = calculateMoneyOut(adjustedLedger);
    setOutTotal(totalOut);
  };

  useEffect(() => {
    computeInOutPercent();
  }, [sortedLedger]);

  useEffect(() => {
    const data = {
      labels: labels,
      datasets: [
        {
          label: i18next.t<string>("result.balance.moneyin"),
          data: [inTotal, 0],
          backgroundColor: PengerInnColour,
        },
        {
          label: i18next.t<string>("result.balance.moneyout"),
          data: [0, outTotal],
          backgroundColor: PengerUtColour,
        },
      ],
    };
    setGraphData(data);
  }, [inTotal, outTotal]);

  useEffect(() => {
    setSortedLedger(sortLedger(ledger));
  }, [ledger]);

  useEffect(() => {
    computeInOutPercent();
  }, [adjustments]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const overspending = inTotal - outTotal;

  return (
    <StyledBackgroundColour>
      <StyledContainer>
        <StyledContainerSpace>
          <StyledBoxSection>
            <h1>{i18next.t<string>("result.balance.header")}</h1>

            <StyledGraphContainer>
              <Bar options={chartOptions} data={graphData} />
            </StyledGraphContainer>

            <DiffStyledDiv>
              <hr />
              <Grid>
                <Grid.Column textAlign="left" width={14}>
                  <StyledTotalDiv className="heading">
                    {overspending < 0
                      ? i18next.t<string>("result.balance.overspending")
                      : i18next.t<string>("result.balance.savingspotential")}
                  </StyledTotalDiv>
                </Grid.Column>
                <Grid.Column textAlign="right" width={2}>
                  <StyledTotalDiv
                    className={
                      overspending < 0 ? "amountNegative" : "amountPositive"
                    }
                  >
                    {inTotal - outTotal} kr
                  </StyledTotalDiv>
                </Grid.Column>
              </Grid>
              <hr />
            </DiffStyledDiv>
          </StyledBoxSection>
        </StyledContainerSpace>
      </StyledContainer>
    </StyledBackgroundColour>
  );
}

const StyledContainer = styled(Container)`
  padding-top: 9.375rem;
`;

export const StyledTotalDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold !important;
`;

export const DiffStyledDiv = styled.div`
  padding-top: 30px;

  .amountNegative {
    color: red !important;
  }

  hr {
    border-top: 1px solid #ccc;
  }
`;

const CenteredContentSection = styled.div`
  padding: 40px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGraphContainer = styled.div`
  height: 150px;
`;
