import { useEffect, useState } from "react";
import styled from "styled-components";

import { ChartData } from "chart.js";

import {
  graphDataInitialState,
  PengerInnColour,
  PengerUtColour,
} from "../../chart/ChartSettings";
import {
  calculateMoneyIn,
  calculateMoneyOut,
  sortLedger,
} from "../../data/Ledger";

import _ from "lodash";

import {
  StyledBackgroundColour,
  StyledContainerSpace,
  StyledHeader,
} from "../UserDetails";

import i18next from "i18next";
import { StepDefinition, StepsState } from "../../components/Steps";
import StepHeader from "../../components/StepHeader";
import ResultInteract from "../../components/ResultInteract";
import ResultDebt from "../../components/ResultDebt";
import ResultBalance from "../../components/ResultBalance";
import ComparisonGraph from "../../components/ComparisonGraph";
import { Button, Container, Icon } from "semantic-ui-react";
import { StyledBoxSection } from "../../components/StyledBoxSection";
import AdviceModalBenefit from "../../modals/AdviceModalBenefit";
import { Goal, LedgerRow, SifoCategories } from "../../models/models";

interface NextStepProps {
  ledger: Array<LedgerRow>;
  removeLedgerRow: (id: string) => void;
  completeStep: () => void;
  goBack: () => void;
  goToStep: (step: StepDefinition) => void;
  goal: Goal;
  activeStep: StepDefinition | undefined;
  steps: StepsState;
  adjustments: Map<LedgerRowId, AdjustmentAmountPercent>;
  setAdjustments: (_: Map<LedgerRowId, AdjustmentAmountPercent>) => void;
  sifoNumbers: SifoCategories;
}

export type LedgerRowId = string;
export type AdjustmentAmountPercent = string;

export default function NextStep(props: NextStepProps) {
  const {
    ledger,
    completeStep,
    activeStep,
    steps,
    goBack,
    adjustments,
    setAdjustments,
    goToStep,
    removeLedgerRow,
    goal,
    sifoNumbers,
  } = props;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <StyledBackgroundColour>
      <StyledHeader>
        <StepHeader steps={steps} goToStep={goToStep} />
      </StyledHeader>
      <StyledBackgroundColour>
        <StyledContainer>
          <StyledContainerSpace>
            <StyledBoxSection>
              <h1>{i18next.t<string>("nextsteps.income.header")}</h1>
              <StyledTask>
                <StyledTaskText>
                  {i18next.t<string>("nextsteps.task")}
                </StyledTaskText>
                <h2>{i18next.t<string>("nextsteps.income.benefits.title")}</h2>
                <AdviceModalBenefit />
              </StyledTask>
              <StyledTask>
                <StyledTaskText>
                  {i18next.t<string>("nextsteps.task")}
                </StyledTaskText>
                <h2>{i18next.t<string>("nextsteps.income.tax.title")}</h2>
                <AdviceModalBenefit />
              </StyledTask>
              <StyledTask>
                <StyledTaskText>
                  {i18next.t<string>("nextsteps.task")}
                </StyledTaskText>
                <h2>{i18next.t<string>("nextsteps.income.rent.title")}</h2>
                <AdviceModalBenefit />
              </StyledTask>
              <StyledTask>
                <StyledTaskText>
                  {i18next.t<string>("nextsteps.task")}
                </StyledTaskText>
                <h2>{i18next.t<string>("nextsteps.income.salary.title")}</h2>
                <AdviceModalBenefit />
              </StyledTask>
              <StyledTask>
                <StyledTaskText>
                  {i18next.t<string>("nextsteps.task")}
                </StyledTaskText>
                <h2>{i18next.t<string>("nextsteps.income.jobs.title")}</h2>
                <AdviceModalBenefit />
              </StyledTask>
            </StyledBoxSection>
          </StyledContainerSpace>
        </StyledContainer>
      </StyledBackgroundColour>
    </StyledBackgroundColour>
  );
}

export const StyledTotalDiv = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold !important;
`;

export const DiffStyledDiv = styled.div`
  padding-top: 30px;

  .amountNegative {
    color: red !important;
  }

  hr {
    border-top: 1px solid #ccc;
  }
`;

const CenteredContentSection = styled.div`
  padding: 40px;
  margin-bottom: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGraphContainer = styled.div`
  height: 150px;
`;
const StyledContainer = styled(Container)`
  padding-top: 9.375rem;
`;

const StyledTask = styled.div`
  border: 2px solid #3d8eb1;
  border-radius: 6px;
  padding: 2rem 4rem;
  margin: 2rem 4rem;
`;

const StyledTaskText = styled.p`
  color: #3d8eb1;
  font-weight: 700;
`;
