import i18next from "i18next";

interface DropDownItem {
  key: string | number;
  text: string | number;
  value: string | number;
}

export enum YesNo {
  yes = "yes",
  no = "no",
}

export const getTranslatedYesNo = (): DropDownItem[] => {
  const items: DropDownItem[] = [];
  items.push({
    key: "yes",
    text: i18next.t<string>("yes"),
    value: "true",
  });
  items.push({
    key: "no",
    text: i18next.t<string>("no"),
    value: "false",
  });
  return items;
};

export enum Gender {
  male = "male",
  female = "female",
  nonbinary = "nonbinary",
  prefernot = "prefernot",
}

export const getTranslatedGenders = (): DropDownItem[] => {
  const items: DropDownItem[] = [];
  items.push({
    key: "male",
    text: i18next.t<string>("family.genderselections.male"),
    value: "male",
  });
  items.push({
    key: "female",
    text: i18next.t<string>("family.genderselections.female"),
    value: "female",
  });
  items.push({
    key: "nonbinary",
    text: i18next.t<string>("family.genderselections.nonbinary"),
    value: "nonbinary",
  });
  items.push({
    key: "prefernot",
    text: i18next.t<string>("family.genderselections.prefernot"),
    value: "prefernot",
  });
  return items;
};

export interface FamilyMember {
  id: string;
  name: string;
  age: Ages;
  gender: Gender;
  pregnant?: string;
  student?: string;
  freeSfo?: string;
  sfo?: SfoCategories;
  kindergarden?: string;
}

export enum Ages {
  month0_5 = "month0_5",
  month6_11 = "month6_11",
  year1 = "year1",
  year2 = "year2",
  year3 = "year3",
  year4_5 = "year4_5",
  year6_9 = "year6_9",
  year10_13 = "year10_13",
  year14_17 = "year14_17",
  year18_19 = "year18_19",
  year20_30 = "year20_30",
  year31_50 = "year31_50",
  year51_60 = "year51_60",
  year61_66 = "year61_66",
  year67_73 = "year67_73",
  olderThan74 = "olderThan74",
  unknown = "unknown",
}

export const getTranslatedAges = (): DropDownItem[] => {
  const items: DropDownItem[] = [];
  items.push({
    key: "month0_5",
    text: i18next.t<string>("age.month0_5"),
    value: "month0_5",
  });
  items.push({
    key: "month6_11",
    text: i18next.t<string>("age.month6_11"),
    value: "month6_11",
  });
  items.push({
    key: "year1",
    text: i18next.t<string>("age.year1"),
    value: "year1",
  });
  items.push({
    key: "year2",
    text: i18next.t<string>("age.year2"),
    value: "year2",
  });
  items.push({
    key: "year3",
    text: i18next.t<string>("age.year3"),
    value: "year3",
  });
  items.push({
    key: "year4_5",
    text: i18next.t<string>("age.year4_5"),
    value: "year4_5",
  });
  items.push({
    key: "year6_9",
    text: i18next.t<string>("age.year6_9"),
    value: "year6_9",
  });
  items.push({
    key: "year10_13",
    text: i18next.t<string>("age.year10_13"),
    value: "year10_13",
  });
  items.push({
    key: "year14_17",
    text: i18next.t<string>("age.year14_17"),
    value: "year14_17",
  });
  items.push({
    key: "year18_19",
    text: i18next.t<string>("age.year18_19"),
    value: "year18_19",
  });
  items.push({
    key: "year20_30",
    text: i18next.t<string>("age.year20_30"),
    value: "year20_30",
  });
  items.push({
    key: "year31_50",
    text: i18next.t<string>("age.year31_50"),
    value: "year31_50",
  });
  items.push({
    key: "year51_60",
    text: i18next.t<string>("age.year51_60"),
    value: "year51_60",
  });
  items.push({
    key: "year61_66",
    text: i18next.t<string>("age.year61_66"),
    value: "year61_66",
  });
  items.push({
    key: "year67_73",
    text: i18next.t<string>("age.year67_73"),
    value: "year67_73",
  });
  items.push({
    key: "olderThan74",
    text: i18next.t<string>("age.olderThan74"),
    value: "olderThan74",
  });
  items.push({
    key: "unknown",
    text: i18next.t<string>("age.unknown"),
    value: "unknown",
  });
  return items;
};

export const getTranslatedAge = (age: Ages): string => {
  return i18next.t<string>("age." + age);
};

export interface Pet {
  id: string;
  name: string;
  type: string;
}

export enum SfoCategories {
  no = "no",
  fulltime = "fulltime",
  parttime = "parttime",
}

export const getTranslatedSfoCategory = (category: SfoCategories): string => {
  return i18next.t<string>("family.sfo." + category);
};

export const getTranslatedSfoCategories = (): DropDownItem[] => {
  const items: DropDownItem[] = [];
  items.push({
    key: "no",
    text: i18next.t<string>("family.sfo.no"),
    value: "no",
  });
  items.push({
    key: "fulltime",
    text: i18next.t<string>("family.sfo.fulltime"),
    value: "fulltime",
  });
  items.push({
    key: "parttime",
    text: i18next.t<string>("family.sfo.parttime"),
    value: "parttime",
  });
  return items;
};

export enum IncomeCategory {
  wage,
  governmentincome,
  socialsupport,
  housingbenifit,
  other,
  parttime,
  privatefunding,
}

export enum BenenfitCategory {
  finassistance,
  childbenifit,
  unemploymentbenifit,
  sicknessbenifit,
  parentalallowance,
  cashforcarebenifit,
  pension,
  workassessmentallowance,
  employschemebenifit,
  disabilitypension,
  qualificationbenifit,
  transitionalbenifit,
  childsupport,
  childsupportadvance,
  introductionallowance,
  basicchildbenifit,
  assistanceallowance,
  lumpsumgrant,
  carebenifit,
  childcarebenifit,
}

export enum TransactionCategory {
  foodAndDrink = "foodAndDrink",
  clothesAndFootwear = "clothesAndFootwear",
  personalCare = "personalCare",
  toysSportMedia = "toysSportMedia",
  travelcost = "travelcost",
  infantGear = "infantGear",
  otherGroceries = "otherGroceries",
  householdItems = "householdItems",
  furniture = "furniture",
  mediaAndLeisure = "mediaAndLeisure",
  car = "car",
  kindergarden = "kindergarden",
  sfo = "sfo",
  wage = "wage",
  housingbenifit = "housingbenifit",
  governmentincome = "governmentincome",
  insurance = "insurance",
  mortgage = "mortgage",
  carDebt = "carDebt",
  otherDebt = "otherDebt",
  studentDebt = "studentDebt",
  consumerDebt = "consumerDebt",
  creditCardDebt = "creditCardDebt",
  debt = "debt",
  rent = "rent",
  other = "other",
  electricity = "electricity",
  // Regular_Income = "Regular_Income",
  parttime = "parttime",
  socialsupport = "socialsupport",
  tax = "tax",
  saving = "saving",
  privatefunding = "privatefunding",
  undefined = "undefined",
  otherincome = "otherincome",
}

export const getTranslatedCategory = (option: TransactionCategory) =>
  ({
    [TransactionCategory.foodAndDrink]: i18next.t<string>(
      "moneyout.expenses.categories.foodAndDrink"
    ),
    [TransactionCategory.clothesAndFootwear]: i18next.t<string>(
      "moneyout.expenses.categories.clothesAndFootwear"
    ),
    [TransactionCategory.personalCare]: i18next.t<string>(
      "moneyout.expenses.categories.personalCare"
    ),
    [TransactionCategory.toysSportMedia]: i18next.t<string>(
      "moneyout.expenses.categories.toysSportMedia"
    ),
    [TransactionCategory.travelcost]: i18next.t<string>(
      "moneyout.expenses.categories.travelcost"
    ),
    [TransactionCategory.infantGear]: i18next.t<string>(
      "moneyout.expenses.categories.infantGear"
    ),
    [TransactionCategory.otherGroceries]: i18next.t<string>(
      "moneyout.expenses.categories.otherGroceries"
    ),
    [TransactionCategory.householdItems]: i18next.t<string>(
      "moneyout.expenses.categories.householdItems"
    ),
    [TransactionCategory.furniture]: i18next.t<string>(
      "moneyout.expenses.categories.furniture"
    ),
    [TransactionCategory.mediaAndLeisure]: i18next.t<string>(
      "moneyout.expenses.categories.mediaAndLeisure"
    ),
    [TransactionCategory.car]: i18next.t<string>(
      "moneyout.expenses.categories.car"
    ),
    [TransactionCategory.kindergarden]: i18next.t<string>(
      "moneyout.expenses.categories.kindergarden"
    ),
    [TransactionCategory.sfo]: i18next.t<string>(
      "moneyout.expenses.categories.sfo"
    ),

    [TransactionCategory.wage]: i18next.t<string>("moneyin.wage"),
    [TransactionCategory.otherincome]: i18next.t<string>("moneyin.wage"),
    [TransactionCategory.housingbenifit]: i18next.t<string>(
      "moneyin.housingbenifit"
    ),
    [TransactionCategory.governmentincome]: i18next.t<string>(
      "moneyin.governmentincome"
    ),
    [TransactionCategory.insurance]: i18next.t<string>(
      "moneyout.expenses.categories.insurance"
    ),
    [TransactionCategory.otherDebt]: i18next.t<string>(
      "moneyout.debt.categories.otherDebt"
    ),
    [TransactionCategory.debt]: i18next.t<string>("moneyout.expenses.header"),
    [TransactionCategory.mortgage]: i18next.t<string>(
      "moneyout.expenses.categories.mortgage"
    ),
    [TransactionCategory.carDebt]: i18next.t<string>(
      "moneyout.expenses.categories.carDebt"
    ),
    [TransactionCategory.studentDebt]: i18next.t<string>(
      "moneyout.expenses.categories.studentDebt"
    ),
    [TransactionCategory.consumerDebt]: i18next.t<string>(
      "moneyout.expenses.categories.consumerDebt"
    ),
    [TransactionCategory.creditCardDebt]: i18next.t<string>(
      "moneyout.expenses.categories.creditCardDebt"
    ),
    [TransactionCategory.rent]: i18next.t<string>(
      "moneyout.expenses.categories.rent"
    ),
    [TransactionCategory.other]: i18next.t<string>(
      "moneyout.expenses.categories.other"
    ),
    [TransactionCategory.electricity]: i18next.t<string>(
      "moneyout.expenses.categories.electricity"
    ),
    // [TransactionCategory.Regular_Income]: i18next.t<string>("moneyin.wage"),
    [TransactionCategory.parttime]: i18next.t<string>("moneyin.parttime"),
    [TransactionCategory.socialsupport]: i18next.t<string>(
      "moneyin.socialsupport"
    ),
    [TransactionCategory.tax]: i18next.t<string>(
      "moneyout.expenses.categories.tax"
    ),
    [TransactionCategory.privatefunding]: i18next.t<string>(
      "moneyin.privatefunding"
    ),
    [TransactionCategory.saving]: i18next.t<string>("moneyin.saving"),
    [TransactionCategory.undefined]: i18next.t<string>(
      "moneyout.expenses.categories.undefined"
    ),
  }[option]);

export const AllTransactionCategories: Array<string> =
  Object.values(TransactionCategory);

export interface LedgerRow {
  id: string;
  dayOfMonth: number;
  interest?: string;
  amount: number;
  accountFrom: string;
  accountTo: string;
  category: TransactionCategory;
}

export interface Goal {
  name: string;
  amount: number;
}

export interface Car {
  fossil: number;
  electric: number;
}

export interface SifoCategories {
  foodAndDrink: number;
  clothesAndFootwear: number;
  personalCare: number;
  toysSportMedia: number;
  travelcost: number;
  infantGear: number;
  otherGroceries: number;
  householdItems: number;
  furniture: number;
  mediaAndLeisure: number;
  car: number;
  kindergarden: number;
  sfo: number;
}

export enum HouseSituation {
  OWN = "OWN",
  RENT = "RENT",
  UNDEFINED = "UNDEFINED",
}

export interface UserInformation {
  goal: Goal;
  car: Car;
  house: HouseSituation;
  salary?: number;
  otherAssets: string;
}
