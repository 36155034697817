import {
    Dropdown,
  } from "semantic-ui-react";
import i18next from "i18next";
import { EnumType } from "typescript";
import { Gender } from "../../models/models";

interface DropdownItem {
    key: string | number;
    text: string | number;
    value: string | number;
  }

interface DropdownProps {
    items: DropdownItem[];
    onChangeParent: (changed: any | undefined, newValue: any, dataKey: string) => void;
    dataObject: any;
    placeholder: string;
    dataKey: string
    value: string
}

export const DropdownTranslateable = (props: DropdownProps) => (
  <Dropdown
    fluid
    search
    selection
    placeholder={props.placeholder}
    options={props.items}
    onChange={(_, data) => {
        props.onChangeParent(props.dataObject, data.value?.toString() as string, props.dataKey)
    }}
    value={ i18next.t<string>(props.value) }
  />
)