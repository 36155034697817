import "./App.css";
import { Route, Routes } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { StepDefinition, StepsState } from "./components/Steps";
import { useEffect, useState } from "react";
import UserDetails from "./views/UserDetails";
import MoneyIn from "./views/MoneyIn";

import "./i18n";
import { useTranslation } from "react-i18next";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Summary from "./views/Summary";
import styled from "styled-components";
import {
  goBackStep,
  goToSpecificStep,
  progressStep,
  updateSteps,
} from "./data/StepProgressor";
import {
  AdjustmentAmountPercent,
  LedgerRowId,
} from "./components/ResultInteract";
import Home from "./views/Home";
import { Button, Icon } from "semantic-ui-react";
import {
  DefaultStateSummary,
  InitialStepsWithPath,
  StateSummary,
} from "./data/StepsInitialState";
import { calculateMoneyIn, calculateMoneyOut } from "./data/Ledger";
import {
  getCarExpenses,
  getClothesAndFootwear,
  getFoodAndBeverages,
  getfurniture,
  getGamesAndSubscriptions,
  getHouseholdItems,
  getInfantEquipment,
  getKindergarden,
  getMediaAndRecreation,
  getOtherGrocieries,
  getPersonalCare,
  getSFO,
  getStordrift,
  getTravelExpenses,
} from "./components/SifoData";
import MoneyOut from "./views/MoneyOut";
import Result from "./views/Result";
import {
  AllTransactionCategories,
  FamilyMember,
  HouseSituation,
  LedgerRow,
  Pet,
  SifoCategories,
  UserInformation,
} from "./models/models";
import NextStep from "./views/NextStep";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const calculateSifoNumbers = (
  familyMembers: FamilyMember[],
  userDetails: UserInformation
) => {
  let sifoNumbers: SifoCategories = {
    foodAndDrink: 0,
    clothesAndFootwear: 0,
    personalCare: 0,
    toysSportMedia: 0,
    travelcost: 0,
    infantGear: 0,
    otherGroceries: 0,
    householdItems: 0,
    furniture: 0,
    mediaAndLeisure: 0,
    car: 0,
    kindergarden: 0,
    sfo: 0,
  };

  familyMembers.map((member) => {
    const { age, gender, pregnant, student, sfo, freeSfo } = member;
    sifoNumbers = {
      ...sifoNumbers,
      foodAndDrink:
        sifoNumbers["foodAndDrink"] +
        (getFoodAndBeverages(age, gender, pregnant) as number) *
          (getStordrift(familyMembers) ? 0.88 : 1),
      clothesAndFootwear:
        sifoNumbers["clothesAndFootwear"] +
        (getClothesAndFootwear(age, gender) as number),
      personalCare:
        sifoNumbers["personalCare"] + (getPersonalCare(age, gender) as number),
      toysSportMedia:
        sifoNumbers["toysSportMedia"] +
        (getGamesAndSubscriptions(age) as number),
      travelcost:
        sifoNumbers["travelcost"] + (getTravelExpenses(age, student) as number),
      infantGear:
        sifoNumbers["infantGear"] +
        (getInfantEquipment(age, pregnant) as number),
      sfo:
        sifoNumbers["sfo"] +
        (getSFO(freeSfo, userDetails.salary, sfo) as number),
    };
  });

  sifoNumbers = {
    ...sifoNumbers,
    otherGroceries:
      sifoNumbers["otherGroceries"] +
      (getOtherGrocieries(familyMembers) as number),
    householdItems:
      sifoNumbers["householdItems"] +
      (getHouseholdItems(familyMembers) as number),
    furniture:
      sifoNumbers["furniture"] + (getfurniture(familyMembers) as number),
    mediaAndLeisure:
      sifoNumbers["mediaAndLeisure"] +
      (getMediaAndRecreation(familyMembers) as number),
    car:
      sifoNumbers["car"] +
      (getCarExpenses(userDetails.car, familyMembers) as number),
    kindergarden:
      sifoNumbers["kindergarden"] +
      (getKindergarden(familyMembers, userDetails.salary) as number),
  };

  return sifoNumbers;
};

export const InitialUserInfo: UserInformation = {
  goal: { name: "", amount: 0 },
  car: { fossil: 0, electric: 0 },
  house: HouseSituation.UNDEFINED,
  otherAssets: "",
};

function rehydrate<T>(name: string, ifEmpty: T): T {
  const item = localStorage.getItem(name);

  return item ? (JSON.parse(item) as T) : ifEmpty;
}

function rehydrateMap<A, B>(name: string, ifEmpty: Map<A, B>): Map<A, B> {
  const item = localStorage.getItem(name);

  if (item) {
    return new Map<A, B>(JSON.parse(item));
  }

  return ifEmpty;
}

function rehydrateSet<A>(name: string, ifEmpty: Set<A>): Set<A> {
  const item = localStorage.getItem(name);

  if (item) {
    return new Set<A>(JSON.parse(item) as Array<A>);
  }

  return ifEmpty;
}

// function selectNorwegian(): void {
//   i18next.changeLanguage("nb");
// }

// function selectEnglish(): void {
//   i18next.changeLanguage("en")
// }

function App() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [stateSummary, setStateSummary] =
    useState<StateSummary>(DefaultStateSummary);
  const [pets, setPets] = useState<Array<Pet>>(rehydrate("pets", []));
  const [previousData, setPreviousData] = useState<unknown[]>(
    rehydrate("previousData", [])
  );
  const [steps, setSteps] = useState<StepsState>(
    InitialStepsWithPath(
      window.location.pathname,
      DefaultStateSummary,
      rehydrateSet("completedStepGroups", new Set())
    )
  );
  const [familyMembers, setFamilyMembers] = useState<Array<FamilyMember>>(
    rehydrate("familyMembers", [])
  );
  const [ledger, setLedger] = useState<Array<LedgerRow>>(
    rehydrate("ledger", [])
  );
  const [userDetails, setUserDetails] = useState<UserInformation>(
    rehydrate("userDetails", InitialUserInfo)
  );
  const [adjustments, setAdjustments] = useState<
    Map<LedgerRowId, AdjustmentAmountPercent>
  >(
    rehydrateMap<LedgerRowId, AdjustmentAmountPercent>(
      "adjustments",
      new Map<LedgerRowId, AdjustmentAmountPercent>()
    )
  );

  const addFamilyMember = (familyMember: FamilyMember) => {
    setFamilyMembers(familyMembers.concat(familyMember));
  };

  const editFamilyMember = (familyMember: FamilyMember) => {
    setFamilyMembers(
      familyMembers.map((item) =>
        item.id === familyMember.id ? familyMember : item
      )
    );
  };

  const addLedgerRow = (ledgerRow: LedgerRow) => {
    setLedger(ledger.concat(ledgerRow));
  };

  const editLedgerRow = (ledgerRow: LedgerRow) => {
    setLedger(
      ledger.map((item) => (item.id === ledgerRow.id ? ledgerRow : item))
    );
  };

  const addPet = (pet: Pet) => {
    setPets(pets.concat(pet));
  };

  const editPet = (pet: Pet) => {
    setPets(pets.map((item) => (item.id === pet.id ? pet : item)));
  };

  const deleteLedgerRow = (id: string) => {
    const filtered = ledger.filter((row) => {
      return row.id !== id;
    });

    setLedger(filtered);
  };

  const deletePet = (id: string) => {
    const filtered = pets.filter((row) => {
      return row.id !== id;
    });

    setPets(filtered);
  };

  const deleteFamilyMember = (id: string) => {
    const filtered = familyMembers.filter((row) => {
      return row.id !== id;
    });

    setFamilyMembers(filtered);
  };

  useEffect(() => {
    localStorage.setItem(
      "previousData",
      JSON.stringify(Array.from(previousData))
    );
    localStorage.setItem(
      "completedStepGroups",
      JSON.stringify(Array.from(steps.completedGroups.keys()))
    );
    localStorage.setItem("familyMembers", JSON.stringify(familyMembers));
    localStorage.setItem("ledger", JSON.stringify(ledger));
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    localStorage.setItem("pets", JSON.stringify(pets));
    localStorage.setItem(
      "adjustments",
      JSON.stringify(Array.from(adjustments.entries()))
    );
  }, [
    previousData,
    steps,
    familyMembers,
    ledger,
    userDetails,
    adjustments,
    pets,
  ]);

  useEffect(() => {
    const moneyIn = calculateMoneyIn(ledger);
    const moneyOut = calculateMoneyOut(ledger);

    setStateSummary({
      familyMemberCount:
        familyMembers.length > 0 ? familyMembers.length : undefined,
      moneyIn: moneyIn > 0 ? moneyIn.toString() : undefined,
      moneyOut: moneyOut > 0 ? moneyOut.toString() : undefined,
    });
  }, [ledger, familyMembers]);

  useEffect(() => {
    setSteps(updateSteps(steps, stateSummary));
  }, [stateSummary]);

  const completeStep = () => {
    const newState = progressStep(steps, stateSummary);
    setSteps(newState);
    navigate(newState.steps[newState.activeStepId]?.path || "/");
  };

  const goBack = () => {
    const newState = goBackStep(steps, stateSummary);
    setSteps(newState);
    navigate(newState.steps[newState.activeStepId]?.path || "/");
  };

  const goToStep = (step: StepDefinition) => {
    const newState = goToSpecificStep(step, steps, stateSummary);
    setSteps(newState);
    navigate(newState.steps[newState.activeStepId]?.path || "/");
  };

  const openFeedbackForm = () => {
    window.open("https://forms.gle/M6ou5EjrdY4tv8BJ8", "_blank")?.focus();
  };

  const resetSession = () => {
    // clear previous sessions
    localStorage.clear();
    setPets([]);
    setPreviousData([]);
    setSteps(
      InitialStepsWithPath(
        window.location.pathname,
        DefaultStateSummary,
        rehydrateSet("completedStepGroups", new Set())
      )
    );
    setFamilyMembers([]);
    setLedger([]);
    setUserDetails(InitialUserInfo);
    setAdjustments(new Map<LedgerRowId, AdjustmentAmountPercent>());
  };

  const activeStep = steps.steps.find((s) => s.id === steps.activeStepId);

  // const setNorwegian = (): void => {
  //   console.log("Langs: " + i18next.languages.join(", "));
  //   i18next.changeLanguage("nb", (err, t) => {
  //     if (err) return console.log("something went wrong loading lang", err);
  //   })
  //   console.log("Langs: " + i18next.languages.join(", "));
  // };

  // const setEnglish = (): void => {
  //   console.log("Langs: " + i18next.languages.join(", "));
  //   i18next.changeLanguage("en", (err, t) => {
  //     if (err) return console.log("something went wrong loading lang", err);
  //   })
  //   console.log("Langs: " + i18next.languages.join(", "));
  // };

  return (
    <StyledRootDiv className="App">
      <FeedbackButton
        color="purple"
        circular
        onClick={() => openFeedbackForm()}
      >
        {" "}
        <Icon name="chat" />
        {t("feedback")}
      </FeedbackButton>
      <StyledOverridesDiv>
        <StyledBodyDiv>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  // setPreviousData={setPreviousData}
                  // setFamilyMembers={setFamilyMembers}
                  // setLedger={setLedger}
                  // setUserDetails={setUserDetails}
                  // setAdjustments={setAdjustments}
                  // setPets={setPets}
                  resetSession={resetSession}
                />
              }
            />
            <Route
              path="/familie"
              element={
                <UserDetails
                  setPreviousData={setPreviousData}
                  setFamilyMembers={setFamilyMembers}
                  setPets={setPets}
                  setLedger={setLedger}
                  setUserDetails={setUserDetails}
                  setAdjustments={setAdjustments}
                  familyMembers={familyMembers}
                  addFamilyMember={addFamilyMember}
                  editFamilyMember={editFamilyMember}
                  activeStep={activeStep}
                  steps={steps}
                  completeStep={completeStep}
                  goBack={goBack}
                  goToStep={goToStep}
                  userDetails={userDetails}
                  pets={pets}
                  addPet={addPet}
                  editPet={editPet}
                  deletePet={deletePet}
                  deleteFamilyMember={deleteFamilyMember}
                  resetSession={resetSession}
                />
              }
            />

            <Route
              path="/penger-inn"
              element={
                <MoneyIn
                  ledger={ledger}
                  addLedgerRow={addLedgerRow}
                  editLedgerRow={editLedgerRow}
                  removeLedgerRow={deleteLedgerRow}
                  completeStep={completeStep}
                  goBack={goBack}
                  goToStep={goToStep}
                  steps={steps}
                />
              }
            />
            <Route
              path="/penger-ut"
              element={
                <MoneyOut
                  ledger={ledger}
                  addLedgerRow={addLedgerRow}
                  editLedgerRow={editLedgerRow}
                  removeLedgerRow={deleteLedgerRow}
                  completeStep={completeStep}
                  goBack={goBack}
                  goToStep={goToStep}
                  activeStep={activeStep}
                  steps={steps}
                  categories={new Set(AllTransactionCategories)}
                />
              }
            />
            <Route
              path="/resultat"
              element={
                <Result
                  ledger={ledger}
                  removeLedgerRow={deleteLedgerRow}
                  completeStep={completeStep}
                  goal={userDetails.goal}
                  goBack={goBack}
                  activeStep={activeStep}
                  steps={steps}
                  adjustments={adjustments}
                  setAdjustments={setAdjustments}
                  goToStep={goToStep}
                  sifoNumbers={calculateSifoNumbers(familyMembers, userDetails)}
                />
              }
            />
            <Route
              path="/nestesteg"
              element={
                <NextStep
                  ledger={ledger}
                  removeLedgerRow={deleteLedgerRow}
                  completeStep={completeStep}
                  goal={userDetails.goal}
                  goBack={goBack}
                  activeStep={activeStep}
                  steps={steps}
                  adjustments={adjustments}
                  setAdjustments={setAdjustments}
                  goToStep={goToStep}
                  sifoNumbers={calculateSifoNumbers(familyMembers, userDetails)}
                />
              }
            />
            <Route
              path="/end"
              element={
                <Summary
                  ledger={ledger}
                  familyMembers={familyMembers}
                  adjustments={adjustments}
                  removeLedgerRow={deleteLedgerRow}
                  completeStep={completeStep}
                  userDetails={userDetails}
                  previousData={previousData}
                  goBack={goBack}
                  pets={pets}
                  resetSession={resetSession}
                />
              }
            />
          </Routes>
        </StyledBodyDiv>
      </StyledOverridesDiv>
    </StyledRootDiv>
  );
}

export const StyledOverridesDiv = styled.div`
  div,
  p,
  h3,
  h4,
  button,
  table {
    font-family: Regular;
  }
  h1 {
    font-family: Regular;
    font-size: 2.125rem;
    line-height: 2.5rem;
  }
  h2 {
    font-family: Regular;
    font-size: 1.625 rem;
    line-height: 2rem;
  }
`;

export const FeedbackButton = styled(Button)`
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

export const StyledRootDiv = styled.div`
  background-color: #f1f8f8;
  height: 100%;
  min-height: 100vh;
`;

const StyledBodyDiv = styled.div`
  text-align: left;
`;

export default App;
